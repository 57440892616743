import { IKonvaCircleConfig, KonvaCircle } from '../konva/Circle'
import {
  GraphicSymmetryShape,
  IGraphicSymmetryShapeConfig,
} from './SymmetryShape'

export type IGraphicCircleConfig = IGraphicSymmetryShapeConfig &
  IKonvaCircleConfig

export class GraphicCircle extends GraphicSymmetryShape<KonvaCircle> {
  constructor(circleConfig: IGraphicCircleConfig) {
    super({
      ...circleConfig,
      graphic: new KonvaCircle({
        ...circleConfig,
      }),
    })
  }
}
