import * as api from '@lanyan/api'
import { IUser, RemoveFirstType } from '@lanyan/type'
import { AxiosRequestConfig } from 'axios'

import { baseRequest } from '.'

// 获取用户信息
export const getUserInfo = (
  ...params: RemoveFirstType<Parameters<typeof api.getUserInfo>>
) => {
  return api.getUserInfo(baseRequest, ...params)
}

// 获取用户支付信息列表
export const getUserPaymentList = (
  config: Partial<AxiosRequestConfig> = {},
) => {
  return baseRequest.get<{
    account_total: number
    account_balance: number
    payment_list: IUser.Payment.Detail[]
  }>('/payment/list', {
    ...config,
  })
}

// 修改密码
export const changePassword = (
  data: {
    password: string
    new_password: string
  },
  config: Partial<AxiosRequestConfig> = {},
) => {
  return baseRequest.put<void>('/users/password', {
    data,
    ...config,
  })
}
