import { IUser } from '@lanyan/type'
import { Request } from '@lanyan/util'
import { AxiosRequestConfig } from 'axios'

// 获取用户信息
export const getUserInfo = (
  request: Request,
  config: Partial<AxiosRequestConfig> = {},
) => {
  return request.get<IUser.Info>('/users/current', {
    ...config,
  })
}
