import * as api from '@lanyan/api'
import { RemoveFirstType } from '@lanyan/type'
import { AxiosRequestConfig } from 'axios'

import { baseRequest } from '.'

// 刷新token
export const refreshToken = (
  ...params: RemoveFirstType<Parameters<typeof api.refreshToken>>
) => {
  return api.refreshToken(baseRequest, ...params)
}

// 密码登录
export const loginWithPassword = (
  ...params: RemoveFirstType<Parameters<typeof api.loginWithPassword>>
) => {
  return api.loginWithPassword(baseRequest, ...params)
}

// 使用短信验证码登录
export const loginWithSms = (
  data: {
    verify_code: string
    phone: string
  },
  config: Partial<AxiosRequestConfig> = {},
) => {
  return baseRequest.post<{ token: string }>('/auth/login/using-sms', {
    data,
    ...config,
  })
}

// 发送手机短信验证码
export const sendVerificationCode = (
  data: {
    phone: string
  },
  config: Partial<AxiosRequestConfig> = {},
) => {
  return baseRequest.post<void>('/auth/verify-codes/phone', {
    data,
    ...config,
  })
}

// 通过手机号注册
export const signupByPhone = (
  data: {
    phone: string
    password: string
    verify_code: string
  },
  config: Partial<AxiosRequestConfig> = {},
) => {
  return baseRequest.post<{
    id: string
    token: string
  }>('/auth/signup/using-phone', {
    data,
    ...config,
  })
}

// 检查手机号是否已被注册
export const checkPhoneExist = (
  params: {
    phone: string
  },
  config: Partial<AxiosRequestConfig> = {},
) => {
  return baseRequest.get<{ exist: boolean }>('/auth/signup/phone/exist', {
    params,
    ...config,
  })
}

// 重置密码
export const resetPasswordByPhone = (
  data: {
    phone: string
    password: string
    verify_code: string
  },
  config: Partial<AxiosRequestConfig> = {},
) => {
  return baseRequest.post<void>('/auth/password-reset/using-phone', {
    data,
    ...config,
  })
}
