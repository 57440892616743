import {
  GraphicCircle,
  IGraphicCircleConfig,
} from '../../GraphicEditor/graphics/Circle'
import { IMaterialConfig, Material, mixinMaterial } from './Material'

export type ICircleConfig = IGraphicCircleConfig & IMaterialConfig
export interface Circle extends Material {}
export class Circle extends GraphicCircle {
  constructor(circleConfig: ICircleConfig) {
    super({
      ...circleConfig,
    })
    mixinMaterial(this, circleConfig)
  }
}
