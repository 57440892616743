import { createRouter, createWebHistory, RouterView } from 'vue-router'

import { EVersion } from '@/constant/home'
import { useGlobalStore } from '@/store/global'

const routes = [
  {
    path: '/',
    redirect: '/home',
    component: () => import('@/views/Layout/index.vue'),
    children: [
      {
        path: 'home',
        component: () => import('@/views/Home/index.vue'),
      },
      {
        path: 'course',
        component: () => import('@/views/Course/index.vue'),
        redirect: '/course/list',
        children: [
          {
            path: 'list',
            name: 'list',
            component: () => import('@/views/Course/List.vue'),
          },
          {
            path: 'manual-create',
            name: 'manualCreate',
            component: () => import('@/views/Course/ManualCreateCourse.vue'),
          },
          {
            path: 'detail/:courseId',
            name: 'courseDetail',
            component: () => import('@/views/Course/CourseDetail.vue'),
          },
          {
            path: 'course/:courseId',
            name: 'course',
            component: () => import('@/views/Course/Course.vue'),
          },
          {
            path: 'courseware/:courseId/:sectionId',
            name: 'courseware',
            component: () => import('@/views/Course/Courseware/index.vue'),
          },
        ],
      },
      {
        path: 'video',
        component: () => Promise.resolve(RouterView),
        redirect: '/video/list',
        children: [
          {
            path: 'list',
            component: () => import('@/views/Video/Library.vue'),
          },
          {
            path: 'player/:id',
            name: 'player',
            component: () => import('@/views/Video/Player.vue'),
          },
          {
            path: 'folder-detail',
            name: 'folderDetail',
            component: () => import('@/views/Video/FolderDetail.vue'),
          },
        ],
      },
      {
        path: 'recycle',
        component: () => import('@/views/Recycle/index.vue'),
      },
      {
        path: 'material',
        component: () => import('@/views/Material/index.vue'),
      },
      {
        path: 'actor',
        component: () => import('@/views/Actor/index.vue'),
      },
    ],
  },
  {
    path: '/dialog-create-course',
    name: 'dialogCreateCourse',
    component: () => import('@/views/Course/DialogCreateCourse/index.vue'),
  },
  { path: '/login', component: () => import('@/views/Login/index.vue') },
  {
    path: '/video-editor/:id',
    name: 'videoEditor',
    component: () => import('@/views/VideoEditor/index.vue'),
  },
  {
    path: '/service-info',
    component: () => import('@/views/TermsOfService/index.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  // 由于vue在初始化Router时还没有pinia实例，所以store的初始化只能放到这儿
  const globalStore = useGlobalStore()
  const version = globalStore.version

  if (to.path === '/home') {
    if (version === EVersion.LAN_YAN) {
      next()
    } else {
      next('/course')
    }
  } else {
    next()
  }
})

// const WHITELIST = ['/login']

// router.beforeEach(async (to, from, next) => {
//   const token = getToken()
//   if (token) {
//     if (to.path === '/login') {
//       const { redirect } = to.query
//       next((redirect as string) ?? '/')
//     } else {
//       await userStore.setUserInfo()
//       if (userStore.userInfo) {
//         next()
//       } else {
//         next({ path: '/login', query: { redirect: to.path } })
//       }
//     }
//   } else {
//     if (WHITELIST.indexOf(to.path)) {
//       next()
//     } else {
//       next({ path: '/login', query: { redirect: to.path } })
//     }
//   }
// })

export default router
