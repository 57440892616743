import { EUserType } from '@lanyan/constant'
import { IUser } from '@lanyan/type'
import { formatSecondToTimeString } from '@lanyan/util'
import { defineStore } from 'pinia'

import { getUserInfo } from '@/api/users'
import router from '@/router'
import { token } from '@/utils/token'

interface IUserState {
  userInfo?: IUser.Info
}

export const useUserStore = defineStore('user', {
  state: (): IUserState => {
    return {
      userInfo: undefined,
    }
  },
  getters: {
    // 余额展示文本。
    balanceText(): string {
      return formatSecondToTimeString(this.userInfo?.remain_time ?? 0)
    },

    isInternal(): boolean {
      return this.userInfo?.user_type === EUserType.INTERNAL
    },
  },
  actions: {
    // 如果需要的话获取用户信息。
    async getUserInfoIfNeed() {
      if (!this.userInfo) {
        return this.getUserInfo()
      }
    },

    // 获取用户信息。
    async getUserInfo() {
      const [getUserInfoErr, getUserInfoRes] = await getUserInfo()
      if (!getUserInfoErr) {
        this.userInfo = getUserInfoRes.data
      }
    },

    // 登出。
    logout() {
      token.clear()

      this.userInfo = undefined

      router.push('/login')
    },
  },
})
