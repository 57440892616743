import { Image } from './Image'

export interface Background {
  getOperateAttrs(): ReturnType<Image['getOperateAttrs']>
}

export const mixinBackground = <T extends Background>(target: T) => {
  target.getOperateAttrs = () => {
    return {
      ...target.constructor.prototype.getOperateAttrs.call(target),
      croppable: false,
      draggable: false,
      resizeEnabled: false,
      selectable: false,
    }
  }
}
