import '@/styles/index.less'

import * as Sentry from '@sentry/vue'
import { createApp } from 'vue'

import router from '@/router'
import store from '@/store'

import App from './App.vue'
import { useGlobalStore } from './store/global'
import { useUserStore } from './store/user'
import { token } from './utils/token'

const app = createApp(App)

if (import.meta.env.MODE === 'production') {
  Sentry.init({
    app,
    environment: import.meta.env.MODE,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost'],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay({
        networkDetailAllowUrls: [new RegExp(import.meta.env.VITE_BASE_API_URL)],
      }),
    ],
    tracingOptions: {
      trackComponents: true,
      timeout: 500,
      hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    },
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

app.use(router).use(store).mount('#app')

const userStore = useUserStore()
const globalStore = useGlobalStore()
const WHITELIST = ['/login', '/service-info']

// 获取列表状态
globalStore.getFoldStatus()

router.beforeEach(async (to, from, next) => {
  if (WHITELIST.includes(to.path)) {
    next()
  } else if (token.get()) {
    if (to.path === '/login') {
      const { redirect } = to.query
      next((redirect as string) ?? '/')
    } else {
      await userStore.getUserInfoIfNeed()
      if (userStore.userInfo) {
        next()
      } else {
        next({ path: '/login', query: { redirect: to.path } })
      }
    }
  } else {
    next({ path: '/login', query: { redirect: to.path } })
  }
})
