import { isNil } from 'lodash-es'

import { EGraphicEvent } from '../config'
import { KonvaArrow } from '../konva/Arrow'
import { IKonvaLineConfig, KonvaLine } from '../konva/Line'
import {
  calcDegreeByTwoPoints,
  calcLengthByTwoPoints,
  calcLinePoints,
  calcMidpoint,
  rectToBox,
  rectToPoints,
  rotatePoint,
  roundTo1,
} from '../util'
import { GraphicShape, IGraphicShapeConfig } from './Shape'

const DESELECT_HIT_WIDTH = 20 // 非选中状态下的鼠标悬停命中宽度。
const DISPLAY_LINE_WIDTH = 1 // 显示线条宽度。
export type IGraphicLineShapeConfig = IGraphicShapeConfig & IKonvaLineConfig

export class GraphicLineShape<
  T extends KonvaLine | KonvaArrow = KonvaLine,
> extends GraphicShape<T> {
  constructor(graphicLineConfig: IGraphicLineShapeConfig & { graphic: T }) {
    const [x1, y1, x2, y2] = graphicLineConfig.points || []
    super({
      ...graphicLineConfig,
      width: calcLengthByTwoPoints([x1, y1], [x2, y2]),
      height: DISPLAY_LINE_WIDTH,
    })

    let lineLength = 0
    this.on(EGraphicEvent.RESIZE_START, () => {
      lineLength = this.lineLength
    })
    this.on(EGraphicEvent.DRAG_POINT, () => {
      this.graphic.position(this.box.position())
    })
    this.on(EGraphicEvent.RESIZE, () => {
      this.graphic.position(this.box.position())
      this.graphic.scale(this.box.scale())
    })
    this.on(EGraphicEvent.RESIZE_END, () => {
      const scale = this.box.scaleX()

      const [x1, y1, x2, y2] = this.graphic.points()
      this.graphic.setAttrs({
        scaleX: 1,
        scaleY: 1,

        points: [x1, y1, x2 * scale, y2 * scale],
      })
      this.box.setAttrs({
        width: lineLength * scale,
        scaleX: 1,
        scaleY: 1,
      })
    })

    this.on(EGraphicEvent.DRAG_MOVE, () => {
      this.graphic.position(this.box.position())
    })

    this.on(EGraphicEvent.ADD_TO, () => {
      const dx = Math.abs(x2 - x1)
      const dy = Math.abs(y2 - y1)
      const isDiagonalDown = y2 > y1
      this.graphic.setAttrs({
        points: [0, 0, dx, isDiagonalDown ? dy : -dy],
        y: isDiagonalDown ? 0 : dy,
      })
      this.box.setAttrs({
        name: 'lineBox',
        offsetY: this.box.height() / 2,
        rotation: this.degree,
        opacity: 0,
        y: isDiagonalDown ? 0 : dy,
      })
      this.box.fill(this.graphicEditor!.primaryColor)
    })
    this.on(EGraphicEvent.GRAPHIC_SELECTION_CHANGE, () => {
      this.box.opacity(+(this.isSelected && this.selectedGraphics.length === 1))
    })

    this.on(EGraphicEvent.UNGROUP, () => {
      this.box.opacity(1)
    })
    this.on(EGraphicEvent.GROUP, () => {
      this.box.opacity(0)
    })
  }

  // 旋转角度。
  get degree() {
    const [x1, y1, x2, y2] = this.graphic.points()!
    let degree = calcDegreeByTwoPoints([x1, y1], [x2, y2])

    if (this.isDiagonalUp) {
      degree = -degree
    }

    return degree
  }

  // 设置或获取 points
  points(points?: number[]) {
    if (isNil(points)) {
      const { x, y } = this.clientRect
      const actualLineLength = this.actualLineLength

      const endPoint = rotatePoint(x + actualLineLength, y, x, y, this.degree)

      return [
        roundTo1(this.graphicEditor!.actualPosToLogicalPos(x, 'x')),
        roundTo1(this.graphicEditor!.actualPosToLogicalPos(y, 'y')),
        roundTo1(this.graphicEditor!.actualPosToLogicalPos(endPoint[0], 'x')),
        roundTo1(this.graphicEditor!.actualPosToLogicalPos(endPoint[1], 'y')),
      ]
    }

    const startX = points[0] - this.containerOriginPos.x
    const startY = points[1] - this.containerOriginPos.y
    const endX = points[2] - this.containerOriginPos.x
    const endY = points[3] - this.containerOriginPos.y
    this.graphic.position({ x: startX, y: startY })
    this.graphic.points([0, 0, endX - startX, endY - startY])
    this.box.position({ x: startX, y: startY })
    this.box.width(calcLengthByTwoPoints([startX, startY], [endX, endY]))
    this.box.rotation(this.degree)

    return points
  }

  // 重写父类的 x 方法。
  x(x?: number) {
    if (isNil(x)) {
      const x = this.box.getClientRect().x + this.boxOffset.offsetX

      return roundTo1(this.graphicEditor!.actualPosToLogicalPos(x, 'x'))
    }

    x -= this.containerOriginPos.x
    this.box.x(x)
    this.graphic.x(x)

    return x
  }

  // 重写父类的 y 方法。
  y(y?: number) {
    if (isNil(y)) {
      const y = this.box.getClientRect().y + this.boxOffset.offsetY

      return roundTo1(this.graphicEditor!.actualPosToLogicalPos(y, 'y'))
    }

    y -= this.containerOriginPos.y

    if (this.isDiagonalUp) {
      const [, y1, , y2] = this.graphic.points()
      y += y1 - y2
    }

    this.box.y(y)
    this.graphic.y(y)

    return y
  }

  // 重写父类的 height 方法。
  width(width?: number) {
    const [x1, y1, x2, y2] = this.graphic.points()!
    if (isNil(width)) {
      return calcLengthByTwoPoints([x1, y1], [x2, y2])
    }

    // 将角度转换为弧度。
    const angleInRadians = Math.abs(this.degree) * (Math.PI / 180)

    // 计算终点坐标。
    const endX = x1 + width * Math.cos(angleInRadians)
    const endY = y1 + width * Math.sin(angleInRadians)

    this.graphic.points([x1, y1, endX, endY])
    this.box.width(width)

    this.fire(EGraphicEvent.DIMENSION_CHANGE)

    return width
  }

  // 线条长度。
  get lineLength() {
    const [x1, y1, x2, y2] = this.graphic.points()!

    return calcLengthByTwoPoints([x1, y1], [x2, y2])
  }

  // 线条实际长度。
  get actualLineLength() {
    return this.graphicEditor!.logicalSizeToActualSize(this.lineLength)
  }

  get boxOffset() {
    const radian = Math.abs(this.degree) * (Math.PI / 180)
    const side = this.graphicEditor!.logicalSizeToActualSize(
      this.box.height() / 2,
    )
    const offsetX = Math.sin(radian) * side
    const offsetY = Math.cos(radian) * side

    return {
      offsetX,
      offsetY,
    }
  }

  // 实际矩形框。
  get clientRect() {
    const rect = this.box.getClientRect()

    const { offsetX, offsetY } = this.boxOffset

    const x = rect.x + offsetX
    const width = rect.width - offsetX

    const y = this.isDiagonalDown
      ? rect.y + offsetY
      : rect.y - offsetY + rect.height
    const height = rect.height - offsetY

    return {
      width,
      height,
      x,
      y,
    }
  }

  // 图形实际矩形信息，作为其他尺寸的基准参考。
  get actualRectBox() {
    const { x, y } = this.clientRect

    const rect = {
      x,
      y,
      height: this.graphicEditor!.logicalSizeToActualSize(DISPLAY_LINE_WIDTH),
      width: this.actualLineLength,
    }

    return rectToBox(rect)
  }

  // 图形实际点坐标，作为其他尺寸的基准参考。
  get actualPoints() {
    const actualRectBox = this.actualRectBox
    actualRectBox.y = actualRectBox.y - actualRectBox.height / 2
    actualRectBox.y1 = actualRectBox.y1 - actualRectBox.height / 2
    actualRectBox.y2 = actualRectBox.y2 - actualRectBox.height / 2

    const [ox, oy] = calcMidpoint(
      [actualRectBox.x, actualRectBox.y],
      [actualRectBox.x, actualRectBox.y2],
    )

    return rectToPoints({ ...actualRectBox, ox, oy, rotation: this.rotation() })
  }

  // 用于命中检测的图形实际矩形框。
  get hitActualRectBox() {
    if (this.isGrouped) {
      return this.calcActualRectBox
    }

    const { x, y } = this.clientRect
    const actualLineLength = this.actualLineLength
    const strokeWidth = this.isSelected
      ? this.strokeWidth()
      : DESELECT_HIT_WIDTH
    const points = calcLinePoints(
      [x, y],
      [x + actualLineLength, y],
      strokeWidth,
    )

    return rectToBox({
      x: points[0][0],
      y: points[0][1],
      width: actualLineLength,
      height: strokeWidth,
    })
  }

  // 用于命中检测的图形实际点坐标。
  get hitActualPoints() {
    if (this.isGrouped) {
      return this.calcActualPoints
    }

    const hitActualRectBox = this.hitActualRectBox
    const [ox, oy] = calcMidpoint(
      [hitActualRectBox.x, hitActualRectBox.y],
      [hitActualRectBox.x, hitActualRectBox.y2],
    )

    return rectToPoints({
      ...hitActualRectBox,
      ox,
      oy,
      rotation: this.rotation(),
    })
  }

  // 命中图形后展示的实际矩形信息。
  get hoverActualRectBox() {
    if (this.isGrouped) {
      return this.calcActualRectBox
    }

    const { x, y } = this.clientRect

    const rect = {
      x,
      y,
      height: this.graphicEditor!.logicalSizeToActualSize(this.box.height()),
      width: this.actualLineLength,
    }

    return rectToBox(rect)
  }

  // 命中图形后展示的实际点坐标。
  get hoverActualPoints() {
    if (this.isGrouped) {
      return this.calcActualPoints
    }

    const hoverActualRectBox = this.hoverActualRectBox
    hoverActualRectBox.y = hoverActualRectBox.y - hoverActualRectBox.height / 2
    hoverActualRectBox.y1 =
      hoverActualRectBox.y1 - hoverActualRectBox.height / 2
    hoverActualRectBox.y2 =
      hoverActualRectBox.y2 - hoverActualRectBox.height / 2
    const [ox, oy] = calcMidpoint(
      [hoverActualRectBox.x, hoverActualRectBox.y],
      [hoverActualRectBox.x, hoverActualRectBox.y2],
    )

    return rectToPoints({
      ...hoverActualRectBox,
      ox,
      oy,
      rotation: this.rotation(),
    })
  }

  // 用于计算的图形实际矩形框。
  get calcActualRectBox() {
    const { x, y, width, height } = this.clientRect
    if (this.isDiagonalDown) {
      return rectToBox({
        x,
        y,
        width,
        height,
      })
    }

    return rectToBox({
      x,
      y: y - height,
      width,
      height,
    })
  }

  // 用于计算的图形实际点坐标。
  get calcActualPoints() {
    return rectToPoints({
      ...this.calcActualRectBox,
      rotation: 0, // 保持矩形。
    })
  }

  // 重写父类的 height 方法。
  height(height?: number) {
    height

    return 0
  }

  // 是否应该禁用修改属性。
  shouldDisableModifyAttr(attr: string) {
    if (['height'].includes(attr)) {
      return true
    }

    return false
  }

  // 是否为向下的对角线。
  get isDiagonalDown() {
    const [, y1, , y2] = this.graphic.points()

    return y2 > y1
  }

  // 是否为向上的对角线。
  get isDiagonalUp() {
    return !this.isDiagonalDown
  }

  // 线条属性。
  get attrs() {
    return {
      ...super.attrs,
      points: this.points(),
    }
  }

  // 操作属性。
  getOperateAttrs() {
    return {
      ...super.getOperateAttrs(),
      borderEnabled: false,
      resizeEnabled: false,
      rotateEnabled: false,
    }
  }
}
