import { isNil } from 'lodash-es'

import { EGraphicEvent } from '../config'
import { KonvaCircle } from '../konva/Circle'
import { KonvaRegularPolygon } from '../konva/RegularPolygon'
import { ANGLE_RESIZE_ANCHOR_LIST } from '../konva/Transformer'
import { roundTo1 } from '../util'
import {
  GraphicCenterBasedShape,
  IGraphicCenterBasedShapeConfig,
} from './CenterBasedShape'

export type IGraphicSymmetryShapeConfig = IGraphicCenterBasedShapeConfig
export type IGraphicSymmetryShape = KonvaRegularPolygon | KonvaCircle

export abstract class GraphicSymmetryShape<
  T extends IGraphicSymmetryShape,
> extends GraphicCenterBasedShape<T> {
  constructor(
    graphicSymmetryShapeConfig: IGraphicSymmetryShapeConfig & {
      graphic: T
    },
  ) {
    super({
      ...graphicSymmetryShapeConfig,
    })

    // 处理拖拽。
    this.on(EGraphicEvent.DRAG_MOVE, () => {
      const { x, y } = this.box.position()
      const radius = this.graphic.radius()
      this.graphic.position({
        x: x + radius,
        y: y + radius,
      })
    })

    // 处理缩放。
    this.on(EGraphicEvent.RESIZE, () => {
      const { width } = this.calcActualRectBox
      const radius = this.graphicEditor!.actualSizeToLogicalSize(width) / 2
      this.graphic.setAttrs({
        x: this.box.x() + radius,
        y: this.box.y() + radius,
        scaleX: 1,
        scaleY: 1,
        radius,
      })
    })

    this.on(EGraphicEvent.ADD_TO, () => {
      const { width = 0 } = graphicSymmetryShapeConfig
      const radius = width / 2
      this.graphic.setAttrs({
        x: radius,
        y: radius,
        radius,
      })
    })
  }

  // 重写父类的 x 方法。
  x(x?: number) {
    if (isNil(x)) {
      return super.x()
    }

    x = super.x(x)
    this.graphic.x(this.box.x() + this.width() / 2)

    return x
  }

  // 重写父类的 y 方法。
  y(y?: number) {
    if (isNil(y)) {
      return super.y()
    }

    y = super.y(y)
    this.graphic.y(this.box.y() + this.height() / 2)

    return y
  }

  // 获取大小。
  getSize() {
    return roundTo1(this.graphic.radius() * 2)
  }

  // 设置大小。
  setSize(size: number) {
    super.width(size)
    super.height(size)
    const radius = size / 2
    this.graphic.radius(radius)
    this.graphic.x(this.box.x() + radius)
    this.graphic.y(this.box.y() + radius)
  }

  // 重写父类的 width 方法。
  width(width?: number) {
    if (isNil(width)) {
      return this.getSize()
    }

    this.setSize(width)

    return width
  }

  // 重写父类的 height 方法。
  height(height?: number) {
    if (isNil(height)) {
      return this.getSize()
    }

    this.setSize(height)

    return height
  }

  getOperateAttrs(): ReturnType<
    GraphicCenterBasedShape<IGraphicSymmetryShape>['getOperateAttrs']
  > {
    return {
      ...super.getOperateAttrs(),
      keepRatio: true,
      enabledAnchors: ANGLE_RESIZE_ANCHOR_LIST,
    }
  }
}
