import { Request } from '@lanyan/util'
import { AxiosRequestConfig } from 'axios'

// 刷新token
export const refreshToken = (
  request: Request,
  config: Partial<AxiosRequestConfig> = {},
) => {
  return request.post<{ token: string }>('/users/refresh-token', {
    ...config,
  })
}

// 使用密码登录
export const loginWithPassword = (
  request: Request,
  data: {
    login_id: string
    password: string
  },
  config: Partial<AxiosRequestConfig> = {},
) => {
  return request.post<{ token: string }>('/auth/login/using-password', {
    data,
    ...config,
  })
}
