// 扩展名。
export enum EExtension {
  // 压缩包。
  ZIP = 'zip',

  // 文件。
  XLSX = 'xlsx',
  XLS = 'xls',
  DOC = 'doc',
  DOCX = 'docx',
  PPT = 'ppt',
  PPTX = 'pptx',
  PDF = 'pdf',

  // 图片。
  BMP = 'bmp',
  GIF = 'gif',
  PNG = 'png',
  JPG = 'jpg',
  SVG = 'svg',
  JPEG = 'jpeg',

  HTML = 'html',

  // 视频。
  MP4 = 'mp4',
  AVI = 'avi',
  MOV = 'mov',
  WMV = 'wmv',
  MKV = 'mkv',
  FLV = 'flv',
  MPEG = 'mpeg',
  MPG = 'mpg',
  WEBM = 'webm',

  // 音频。
  MP3 = 'mp3',

  // css
  CSS = 'css',

  // 字体。
  TTF = 'ttf',
  OTF = 'otf',
  WOFF = 'woff',
  WOFF2 = 'woff2',

  // json
  JSON = 'json',

  // 动画。
  PAG = 'pag',

  // 文本。
  TXT = 'txt',
}

// mime 类型。
export enum EMime {
  ZIP = 'application/zip',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XLS = 'application/vnd.ms-excel',
  BMP = 'image/bmp',
  GIF = 'image/gif',
  PNG = 'image/png',
  SVG = 'image/svg',
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  PPT = 'application/vnd.ms-powerpoint',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  PDF = 'application/pdf',
  HTML = 'text/html',
  MP4 = 'video/mp4',
  AVI = 'video/x-msvideo',
  MOV = 'video/quicktime',
  WMV = 'video/x-ms-wmv',
  MKV = 'video/x-matroska',
  FLV = 'video/x-flv',
  MPEG = 'video/mpeg',
  MPG = 'video/mpg',
  WEBM = 'video/webm',
  MP3 = 'audio/mp3',
  CSS = 'text/css',
  JSON = 'application/json',
  TTF = 'font/ttf',
  OTF = 'font/otf',
  WOFF = 'font/woff',
  WOFF2 = 'font/woff2',
  PAG = 'application/x-pag',
  TXT = 'text/plain',
}

// 扩展名 mime 映射
export const EXTENSION_MIME_MAP = {
  [EExtension.ZIP]: EMime.ZIP,
  [EExtension.XLSX]: EMime.XLSX,
  [EExtension.XLS]: EMime.XLS,
  [EExtension.BMP]: EMime.BMP,
  [EExtension.GIF]: EMime.GIF,
  [EExtension.PNG]: EMime.PNG,
  [EExtension.SVG]: EMime.SVG,
  [EExtension.JPG]: EMime.JPG,
  [EExtension.JPEG]: EMime.JPEG,
  [EExtension.DOC]: EMime.DOC,
  [EExtension.DOCX]: EMime.DOCX,
  [EExtension.PPT]: EMime.PPT,
  [EExtension.PPTX]: EMime.PPTX,
  [EExtension.PDF]: EMime.PDF,
  [EExtension.HTML]: EMime.HTML,
  [EExtension.MP4]: EMime.MP4,
  [EExtension.AVI]: EMime.AVI,
  [EExtension.MOV]: EMime.MOV,
  [EExtension.WMV]: EMime.WMV,
  [EExtension.MKV]: EMime.MKV,
  [EExtension.FLV]: EMime.FLV,
  [EExtension.MPEG]: EMime.MPEG,
  [EExtension.MPG]: EMime.MPG,
  [EExtension.WEBM]: EMime.WEBM,
  [EExtension.MP3]: EMime.MP3,
  [EExtension.CSS]: EMime.CSS,
  [EExtension.JSON]: EMime.JSON,
  [EExtension.TTF]: EMime.TTF,
  [EExtension.OTF]: EMime.OTF,
  [EExtension.WOFF]: EMime.WOFF,
  [EExtension.WOFF2]: EMime.WOFF2,
  [EExtension.PAG]: EMime.PAG,
  [EExtension.TXT]: EMime.TXT,
} as const

// ppt 文件扩展名列表
export const PPT_EXTENSIONS = [EExtension.PPT, EExtension.PPTX]

// ppt 文件 mime 列表
export const PPT_MIME = PPT_EXTENSIONS.map((pptExt) => {
  return EXTENSION_MIME_MAP[pptExt] as string
})

// 图片文件扩展名列表。
export const IMAGE_EXTENSIONS = [
  EExtension.PNG,
  EExtension.JPG,
  EExtension.JPEG,
]

// 图片文件 mime 列表
export const IMAGE_MIME = IMAGE_EXTENSIONS.map((imageExt) => {
  return EXTENSION_MIME_MAP[imageExt] as string
})

// 视频文件扩展名列表。
export const VIDEO_EXTENSIONS = [EExtension.MP4, EExtension.WEBM]

// 视频文件 mime 列表
export const VIDEO_MIME = VIDEO_EXTENSIONS.map((videoExt) => {
  return EXTENSION_MIME_MAP[videoExt] as string
})

// 音频文件扩展名列表。
export const AUDIO_EXTENSIONS = [EExtension.MP3]

// 音频文件 mime 列表
export const AUDIO_MIME = AUDIO_EXTENSIONS.map((audioExt) => {
  return EXTENSION_MIME_MAP[audioExt] as string
})
