import { chunk, cloneDeep, debounce, isNil } from 'lodash-es'

import { EFillPriority, EGraphicEvent } from '../config'
import { KonvaArrow } from '../konva/Arrow'
import { KonvaCircle } from '../konva/Circle'
import { KonvaEllipse } from '../konva/Ellipse'
import { KonvaLine } from '../konva/Line'
import { KonvaRect } from '../konva/Rect'
import { KonvaRegularPolygon } from '../konva/RegularPolygon'
import { RESIZE_ANCHOR_LIST } from '../konva/Transformer'
import { createLinearGradient } from '../konva/util'
import { IPos } from '../type'
import { getLinearGradientParamsByRect } from '../util'
import { Graphic, IGraphicConfig } from './Graphic'

export type IGraphicShapeConfig = IGraphicConfig & {
  fillPriority?: `${EFillPriority}`
  linearGradientColorStops?: [number, string][]
  linearGradientDegree?: number
  strokeLinearGradientColorStops?: [number, string][]
  strokeLinearGradientDegree?: number
  strokePriority?: `${EFillPriority}`
}
type IKonvaShape =
  | KonvaRect
  | KonvaRegularPolygon
  | KonvaCircle
  | KonvaLine
  | KonvaArrow
  | KonvaEllipse
export abstract class GraphicShape<T extends IKonvaShape> extends Graphic<T> {
  // 是否第一次绘制完成。
  firstDrawIsComplete = false

  // 填充线性渐变角度。
  _linearGradientDegree = 0

  // 描边线性渐变角度。
  _strokeLinearGradientDegree = 0

  // 描边线性渐变颜色。
  _strokeLinearGradientColorStops: [number, string][] = []

  // 描边优先级。
  _strokePriority: `${EFillPriority}`

  // 描边颜色。
  _stroke: string

  constructor(graphicShapeConfig: IGraphicShapeConfig & { graphic: T }) {
    super({
      ...graphicShapeConfig,
    })

    this.on(EGraphicEvent.ADD_TO, () => {
      this.setFillGradient()
      this.setStrokeGradient()
    })

    this.graphic.fillPriority(
      graphicShapeConfig.fillPriority ?? EFillPriority.COLOR,
    )
    this.graphic.fillLinearGradientColorStops(
      graphicShapeConfig.linearGradientColorStops?.flat() ?? [],
    )
    this._linearGradientDegree = graphicShapeConfig.linearGradientDegree ?? 0
    this._strokeLinearGradientDegree =
      graphicShapeConfig.strokeLinearGradientDegree ?? 0
    this._strokePriority =
      graphicShapeConfig.strokePriority ?? EFillPriority.COLOR
    this._strokeLinearGradientColorStops =
      graphicShapeConfig.strokeLinearGradientColorStops ?? []
    this._stroke = this.graphic.stroke() as string

    // konva 也有这个字段，但是它的逻辑是只判断这个字段存在就会渲染描边渐变
    // 但是我的逻辑是没有渐变时是空数组，然后通过 strokePriority 来判断是否渲染描边渐变
    // @ts-ignore
    this.graphic.strokeLinearGradientColorStops(null)
    requestAnimationFrame(() => {
      this.firstDrawIsComplete = true
      this.fire(EGraphicEvent.FIRST_DRAW_COMPLETE)
    })
    const debouncedSetFillGradient = debounce(
      this.setFillGradient.bind(this),
      0,
    )
    const debouncedSetStrokeGradient = debounce(
      this.setStrokeGradient.bind(this),
      0,
    )
    this.on(EGraphicEvent.DIMENSION_CHANGE, () => {
      debouncedSetFillGradient()
      debouncedSetStrokeGradient()
    })
    this.on(EGraphicEvent.RESIZE, () => {
      debouncedSetFillGradient()
      debouncedSetStrokeGradient()
    })
    this.on(EGraphicEvent.POSITION_CHANGE, () => {
      debouncedSetStrokeGradient()
    })
    this.on(EGraphicEvent.DRAG_MOVE, () => {
      debouncedSetStrokeGradient()
    })
  }

  // 形状属性。
  get attrs() {
    return {
      ...super.attrs,
      fill: this.fill(),
      dash: this.dash(),
      stroke: this.stroke(),
      strokeWidth: this.strokeWidth(),
      opacity: this.opacity(),
      fillPriority: this.fillPriority(),
      linearGradientColorStops: this.linearGradientColorStops(),
      linearGradientDegree: this.linearGradientDegree(),
      strokePriority: this.strokePriority(),
      strokeLinearGradientColorStops: this.strokeLinearGradientColorStops(),
      strokeLinearGradientDegree: this.strokeLinearGradientDegree(),
    }
  }

  // 获取或者设置填充色。
  fill(fill?: string) {
    if (isNil(fill)) {
      return this.graphic.fill() as string
    }

    this.graphic.fill(fill)

    return fill
  }

  // 获取或者设置描边颜色。
  stroke(stroke?: string) {
    if (isNil(stroke)) {
      return this._stroke
    }

    this._stroke = stroke
    if (this.strokePriority() === EFillPriority.COLOR) {
      this.graphic.stroke(stroke)
    }

    return stroke
  }

  // 获取或者设置描边宽度。
  strokeWidth(strokeWidth?: number) {
    if (isNil(strokeWidth)) {
      return this.graphic.strokeWidth()
    }

    this.graphic.strokeWidth(strokeWidth)

    return strokeWidth
  }

  // 获取或者设置虚线。
  dash(dash?: [number, number]) {
    if (isNil(dash)) {
      return cloneDeep(this.graphic.dash() ?? [0, 0]) as [number, number]
    }

    this.graphic.dash([...dash])

    return dash
  }

  // 设置或获取 fillPriority
  fillPriority(fillPriority?: `${EFillPriority}`) {
    if (isNil(fillPriority)) {
      return this.graphic.fillPriority() as `${EFillPriority}`
    }

    this.graphic.fillPriority(fillPriority)
    this.setFillGradient()

    return fillPriority
  }

  // 设置或获取 linearGradientColorStops
  linearGradientColorStops(linearGradientColorStops?: [number, string][]) {
    if (isNil(linearGradientColorStops)) {
      return chunk(
        this.graphic.fillLinearGradientColorStops(),
        2,
      ) as unknown as [number, string][]
    }

    this.graphic.fillLinearGradientColorStops(linearGradientColorStops.flat())
    this.setFillGradient()

    return linearGradientColorStops
  }

  // 设置或获取 fillLinearGradientStartPoint
  fillLinearGradientStartPoint(startPoint?: IPos) {
    if (isNil(startPoint)) {
      return this.graphic.fillLinearGradientStartPoint()
    }

    this.graphic.fillLinearGradientStartPoint(startPoint)
  }

  // 设置或获取 fillLinearGradientEndPoint
  fillLinearGradientEndPoint(endPoint?: IPos) {
    if (isNil(endPoint)) {
      return this.graphic.fillLinearGradientEndPoint()
    }

    this.graphic.fillLinearGradientEndPoint(endPoint)
  }

  // 设置或获取 linearGradientDegree
  linearGradientDegree(linearGradientDegree?: number) {
    if (isNil(linearGradientDegree)) {
      return this._linearGradientDegree
    }

    this._linearGradientDegree = linearGradientDegree
    this.setFillGradient()

    return linearGradientDegree
  }

  // 设置填充渐变。
  setFillGradient() {
    if (this.fillPriority() !== EFillPriority.LINEAR_GRADIENT) {
      return
    }

    const { width, height } = this.attrs
    const { startX, startY, endX, endY } = getLinearGradientParamsByRect({
      x: 0,
      y: 0,
      width,
      height,
      degree: this.linearGradientDegree(),
      colorStops: this.linearGradientColorStops(),
    })
    this.fillLinearGradientStartPoint({
      x: startX,
      y: startY,
    })
    this.fillLinearGradientEndPoint({
      x: endX,
      y: endY,
    })
  }

  // 设置或获取 strokePriority
  strokePriority(strokePriority?: `${EFillPriority}`) {
    if (isNil(strokePriority)) {
      return this._strokePriority
    }

    this._strokePriority = strokePriority
    this.setStrokeGradient()

    return strokePriority
  }

  // 设置或获取 strokeLinearGradientColorStops
  strokeLinearGradientColorStops(
    strokeLinearGradientColorStops?: [number, string][],
  ) {
    if (isNil(strokeLinearGradientColorStops)) {
      return this._strokeLinearGradientColorStops
    }

    this._strokeLinearGradientColorStops = cloneDeep(
      strokeLinearGradientColorStops,
    )
    this.setStrokeGradient()

    return strokeLinearGradientColorStops
  }

  // 设置或获取 strokeLinearGradientDegree
  strokeLinearGradientDegree(strokeLinearGradientDegree?: number) {
    if (isNil(strokeLinearGradientDegree)) {
      return this._strokeLinearGradientDegree
    }

    this._strokeLinearGradientDegree = strokeLinearGradientDegree
    this.setStrokeGradient()

    return strokeLinearGradientDegree
  }

  // 设置描边渐变。
  setStrokeGradient() {
    if (this.strokePriority() === EFillPriority.LINEAR_GRADIENT) {
      const { x, y, width, height } =
        this.graphicEditor!.actualToLogicalRectBox(this.outlineActualRectBox)
      const { startX, startY, endX, endY } = getLinearGradientParamsByRect({
        x: x - this.x(),
        y: y - this.y(),
        width,
        height,
        degree: this.strokeLinearGradientDegree(),
        colorStops: this.strokeLinearGradientColorStops(),
      })
      const gd = createLinearGradient(
        startX,
        startY,
        endX,
        endY,
        this.strokeLinearGradientColorStops(),
      )
      this.graphic.stroke(gd)
    } else {
      this.graphic.stroke(this._stroke)
    }
  }

  // 是否应该禁用修改属性。
  shouldDisableModifyAttr(attr?: string) {
    attr

    return false
  }

  // 操作属性。
  getOperateAttrs(): ReturnType<Graphic['getOperateAttrs']> {
    return {
      enabledAnchors: RESIZE_ANCHOR_LIST,
      keepRatio: false,
      borderEnabled: true,
      resizeEnabled: true,
      maskEnabled: true,
      rotateEnabled: false,
      draggable: true,
      croppable: false,
      selectable: true,
      hoverEnabled: true,
      hasRadius: false,
    }
  }
}
