import { IResponseData } from '@lanyan/type'
import { commonStorage, Request as BaseRequest } from '@lanyan/util'
import { message } from 'ant-design-vue'
import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'
import { isRegExp } from 'lodash-es'

import { refreshToken } from '@/api/auth'
import router from '@/router'
import { token } from '@/utils/token'

const COMMON_ERROR_MSG = '出错了，请稍后再试'

const handleToken = (config: InternalAxiosRequestConfig, token: string) => {
  config.headers.Authorization = `Bearer ${token}`
}

const WHITELIST = [/\/v\d\/auth/]

const shouldSkipAuthInterceptor = ({
  url,
  needAuth,
}: InternalAxiosRequestConfig) => {
  if (needAuth) {
    return false
  }

  return WHITELIST.some((whitelistItem) => {
    if (isRegExp(whitelistItem)) {
      return whitelistItem.test(url!)
    }

    return url!.includes(whitelistItem)
  })
}

const showError = (error?: string) => {
  message.error({
    content: error,
    duration: 5,
  })
}

let isRefreshingToken = false // 是否正在刷新token的标志
const refreshSubscribers: ((token: string) => void)[] = [] // 用来存储刷新token期间被阻塞的请求

class Request extends BaseRequest {
  constructor(...params: ConstructorParameters<typeof BaseRequest>) {
    super(...params)
    this.addRequestInterceptor(
      this.authRequestInterceptor,
      this.commonRequestInterceptorExceptionHandler,
    )

    this.addResponseInterceptor(
      this.commonResponseInterceptor,
      this.commonResponseInterceptorExceptionHandler,
    )
  }

  // 通用请求异常处理器
  commonRequestInterceptorExceptionHandler(error: AxiosError) {
    console.error(error)

    return Promise.reject(error)
  }

  // 通用请求处理器
  async authRequestInterceptor(config: InternalAxiosRequestConfig) {
    const { version = '1' } = config
    if (!/^\/v\d/.test(config.url!)) {
      config.url = `/v${version}${config.url}`
    }

    if (shouldSkipAuthInterceptor(config)) {
      return config
    }

    if (isRefreshingToken) {
      return new Promise<InternalAxiosRequestConfig>((resolve) => {
        refreshSubscribers.push((newToken: string) => {
          handleToken(config, newToken)
          resolve(config)
        })
      })
    }

    const tokenStr = token.get()

    if (tokenStr) {
      handleToken(config, tokenStr)

      return config
    }

    router.push(`/login`)

    return Promise.reject(config)
  }

  // 通用响应处理器
  async commonResponseInterceptor(response: AxiosResponse) {
    const { config, data } = response
    if (config.responseType === 'blob') {
      return data
    }

    if (data.code === 0) {
      return data
    }

    const errorMsg = data.message ?? COMMON_ERROR_MSG
    if (config.loggable) {
      showError(errorMsg)
    }

    return Promise.reject(response)
  }

  // 通用响应异常处理器
  async commonResponseInterceptorExceptionHandler(
    error: AxiosError<IResponseData<any>>,
  ) {
    console.error(error)
    const { status, data } = error.response ?? {}
    const { onCancel, loggable = false } = error.config ?? {}
    if (status === 401 && data?.code === 40102) {
      token.clear()
      isRefreshingToken = true
      const [refreshTokenErr, refreshTokenRes] = await refreshToken()
      isRefreshingToken = false

      if (refreshTokenErr) {
        router.push(`/login`)

        return Promise.reject(refreshTokenErr)
      }

      refreshSubscribers.forEach((sub) => sub(refreshTokenRes.data.token))
    } else if (error instanceof axios.Cancel) {
      console.log('取消请求')
      onCancel?.(error)
    } else {
      if (loggable) {
        showError(error.message)
      }
    }

    return Promise.reject(error)
  }

  async get<T = any>(...params: Parameters<BaseRequest['get']>) {
    const { cacheKeyResolver } = params[1] ?? {}

    let cacheKey = await cacheKeyResolver?.()
    if (cacheKey) {
      cacheKey = [params[0], cacheKey].join('-')
      const cacheData = await commonStorage.getItem(cacheKey)
      if (cacheData) {
        return [null, { code: 0, message: '', data: cacheData as T }] as [
          null,
          IResponseData<T>,
        ]
      }
    }

    const res = await super.get<T>(...params)

    if (res[1] && cacheKey) {
      commonStorage.setItem(`${cacheKey}`, res[1].data)
    }

    return res
  }
}

export default Request
