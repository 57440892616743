import { ANIMATION_DURATION, CHINESE_REG } from '@lanyan/constant'
import { Nullable, Optional } from '@lanyan/type'
import { KonvaEventObject } from 'konva/lib/Node'
import { Util } from 'konva/lib/Util'
import {
  cloneDeep,
  debounce,
  first,
  isEqual,
  isNil,
  last,
  max,
  noop,
  pick,
  sum,
  throttle,
  toArray,
  uniq,
  uniqBy,
} from 'lodash-es'

import { on, onKeyDown, onKeyUp } from '../..//dom'
import { copy } from '../../clipboard'
import { isWebWorker } from '../../env'
import { formatPastedContent } from '../../helper'
import { isApproximatelyEqual } from '../../predicate'
import {
  EAlign,
  EFillPriority,
  EGraphicEvent,
  EGraphicType,
  EKeyboardOperation,
  EVerticalAlign,
} from '../config'
import { KonvaRect } from '../konva/Rect'
import { IKonvaTextConfig, KonvaText } from '../konva/Text'
import { RESIZE_ANCHOR_LIST } from '../konva/Transformer'
import {
  ETextAttr,
  flatTextList,
  getFontFamilyTextMap,
  getTextMatrix,
  getTextStyleByIndex,
  TextListItem,
} from '../konva/util'
import { IPos, ISize } from '../type'
import {
  calculateIntersectionArea,
  checkKeydownOp,
  getKeydownOp,
  roundTo,
  roundTo1,
} from '../util'
import { Graphic, IGraphicConfig } from './Graphic'

// 剪切板的文本列表。
let clipboardTextList: TextListItem[] = []

// 剪切板的文本。
let clipboardText = ''

// 是否是添加文字操作。
export const isAddTextOp = (op: TextChangeOp) =>
  op.addTextList.length && !op.deleteTextList.length

// 是否是删除文字操作。
export const isDeleteTextOp = (op: TextChangeOp) =>
  op.deleteTextList.length && !op.addTextList.length

// 获取文字操作后的最终索引。
const getTextOpFinallyIndex = (op: TextChangeOp) => {
  return op.indexes[0] + op.addTextList.length
}

// 合并文字操作记录。
const mergeTextChangeOp = (textChangeOps: TextChangeOp[]) => {
  const mergedTextChangeOps: TextChangeOp[] = []
  for (let i = 0; i < textChangeOps.length; i++) {
    const op = textChangeOps[i]
    const lastOp = last(mergedTextChangeOps)
    if (lastOp) {
      const clonedLastOp = cloneDeep(lastOp)

      const ifMergeIsLegalThenContinue = () => {
        if (getTextOpFinallyIndex(clonedLastOp) === getTextOpFinallyIndex(op)) {
          Object.assign(lastOp, clonedLastOp)

          return true
        }

        return false
      }

      if (isDeleteTextOp(clonedLastOp)) {
        clonedLastOp.deleteTextList.unshift(...op.deleteTextList)
        clonedLastOp.indexes[0] -= op.deleteTextList.length
        clonedLastOp.addTextList.push(...op.addTextList)
        if (ifMergeIsLegalThenContinue()) {
          continue
        }
      }

      if (isAddTextOp(clonedLastOp)) {
        if (isAddTextOp(op)) {
          clonedLastOp.addTextList.push(...op.addTextList)
          if (ifMergeIsLegalThenContinue()) {
            continue
          }
        }

        if (isDeleteTextOp(op)) {
          clonedLastOp.addTextList = clonedLastOp.addTextList.slice(
            0,
            -op.deleteTextList.length,
          )
          if (ifMergeIsLegalThenContinue()) {
            continue
          }
        }

        clonedLastOp.addTextList = clonedLastOp.addTextList.slice(
          0,
          -op.deleteTextList.length,
        )

        clonedLastOp.addTextList.push(...op.addTextList)
        if (ifMergeIsLegalThenContinue()) {
          continue
        }
      }

      if (isAddTextOp(op)) {
        clonedLastOp.addTextList.push(...op.addTextList)
        if (ifMergeIsLegalThenContinue()) {
          continue
        }
      }

      if (isDeleteTextOp(op)) {
        clonedLastOp.addTextList = clonedLastOp.addTextList.slice(
          0,
          -op.deleteTextList.length,
        )
        if (ifMergeIsLegalThenContinue()) {
          continue
        }

        clonedLastOp.addTextList = clonedLastOp.addTextList.slice(
          0,
          -op.deleteTextList.length,
        )
        clonedLastOp.addTextList.push(...op.addTextList)
        if (ifMergeIsLegalThenContinue()) {
          continue
        }
      }
    }

    mergedTextChangeOps.push(cloneDeep(op))
  }

  return mergedTextChangeOps
}

// 光标所在文本位置。
export enum ECursorSide {
  LEFT = 'left',
  RIGHT = 'right',
}

// 文本操作。
type TextChangeOp = {
  indexes: number[]
  deleteTextList: TextListItem[]
  addTextList: TextListItem[]
}

// 文本属性列表。
export const TEXT_ATTRS = [
  ETextAttr.FONT_FAMILY,
  ETextAttr.FONT_SIZE,
  ETextAttr.LINE_HEIGHT,
  ETextAttr.LETTER_SPACING,
  ETextAttr.FILL,
  ETextAttr.FONT_STYLE,
  ETextAttr.TEXT_DECORATION,
  ETextAttr.LINEAR_GRADIENT_COLOR_STOPS,
  ETextAttr.LINEAR_GRADIENT_DEGREE,
  ETextAttr.FILL_PRIORITY,
] as const

// 文本修改类型。
export enum EModifyType {
  'NONE' = 'none',
  'ENTER' = 'enter',
  'INPUT' = 'input',
  'INPUT_CHINESE' = 'inputChinese',
  'INPUT_CHINESE_END' = 'inputChineseEnd',
  'PASTE' = 'paste',
  'DELETE' = 'delete',
  'CUT' = 'cut',
}

// 光标移动方向。
export enum ECursorMoveDirection {
  LEFT = 'left',
  RIGHT = 'right',
  UP = 'up',
  DOWN = 'down',
}

// 间隔时间。
const INTERVAL = 1000

// 创建一个隐藏的 textarea
let $textarea: Optional<HTMLTextAreaElement>
if (!isWebWorker()) {
  $textarea = document.createElement('textarea')
  $textarea.onblur = () => {
    console.log('blur')
  }
  $textarea.onfocus = () => {
    console.log('focus')
  }
  Object.assign($textarea, {
    wrap: 'off',
    spellcheck: 'off',
    autocomplete: 'off',
    autocorrect: 'off',
    autocapitalize: 'off',
  })
  Object.assign($textarea.style, {
    position: 'absolute',
    opacity: 1,
    zIndex: 999,
    borderRadius: 0,
    outline: 0,
    resize: 'none',
    width: '1px',
    height: '1px',
    cursor: 'text',
    pointerEvents: 'none',
  })
}

// 光标。
export interface ICursor {
  row: number
  col: number
  pos: ECursorSide
}

export type IGraphicTextConfig = IGraphicConfig &
  Pick<IKonvaTextConfig, keyof IPos | keyof ISize | 'id' | 'opacity'> & {
    align?: `${EAlign}`
    verticalAlign?: `${EVerticalAlign}`
  } & {
    showBorder?: boolean
    borderColor?: string
    loadFont: (font: string, text: string) => Promise<boolean | string[]>
    checkFont: (font: string, text: string) => boolean
    onFirstDraw?: (text: GraphicText) => void
    textList: TextListItem[]
  }

export class GraphicText extends Graphic<KonvaText> {
  // 图形类型。
  graphicType = EGraphicType.TEXT

  // 初次绘制是否完成。
  firstDrawIsComplete = false

  // 文字列表。
  textList: TextListItem[] = []

  // 文字矩阵。
  textMatrix: ReturnType<typeof getTextMatrix> = []

  // 文字绝对位置大小矩阵。
  textAbsRectMatrix: (IPos & ISize & { index: number })[][] = []

  // 检查字体。
  checkFont: (font: string, text: string) => boolean

  // 加载字体。
  loadFont: (font: string, text: string) => void

  // 输入框元素。
  $textarea: Nullable<HTMLTextAreaElement> = null

  // 编辑前的属性。
  preAttrs?: GraphicText['attrs']

  // 是否正在输入中文。
  #isInputtingChinese = false

  // 鼠标按下的光标位置。
  #mouseDownCursor?: ICursor

  // 鼠标按下时的选区索引。
  #mouseDownIndexes: number[] = []

  // 边框矩形。
  borderRect?: KonvaRect

  // 事件监听器列表。
  #listeners: (() => void)[] = []

  // 发射文字变化事件。
  #fireTextChangingEvent = noop

  // 防抖重绘选区。
  #debouncedRedrawSelection: () => void

  // 下一个插入的文本的样式。
  nextInsertTextStyle: Partial<Omit<TextListItem, 'text'>> = {}

  // 文本修改类型。
  modifyType = EModifyType.NONE

  // 选区光标位置。
  selectionCursors: ICursor[] = []

  // 之前选区文本索引。
  preSelectionIndexes: number[] = []

  // 文本修改操作里路。
  textChangeOps: TextChangeOp[] = []

  // 当前选区文本索引。
  selectionIndexes: number[] = []

  // 选区开始文本索引。
  selectionStartIndex: number = 0

  // 选区结束文本索引。
  selectionEndIndex: number = 0

  // 选区色块列表。
  #selectionColorRectList: KonvaRect[] = []

  constructor(graphicTextConfig: IGraphicTextConfig) {
    super({
      ...graphicTextConfig,
      graphic: new KonvaText({
        width: graphicTextConfig.width,
        height: graphicTextConfig.height,
        align: graphicTextConfig.align,
        opacity: graphicTextConfig.opacity,
        verticalAlign: graphicTextConfig.verticalAlign,
      }),
    })

    this.checkFont = graphicTextConfig.checkFont
    this.loadFont = graphicTextConfig.loadFont

    // 创建并添加文字边框。
    if (graphicTextConfig.showBorder) {
      this.borderRect = new KonvaRect({
        stroke: graphicTextConfig.borderColor ?? '#999',
        strokeWidth: 1,
        strokeScaleEnabled: false,
        width: this.box.width(),
        height: this.box.height(),
        dash: [10, 20, 5, 20],
      })

      this.group.add(this.borderRect)
    }

    // 初始化文字列表。
    this.setTextList(graphicTextConfig.textList).then(() => {
      // 如果没有设置宽度和高度，则设置为文字宽度和高度。
      if (isNil(graphicTextConfig.width)) {
        super.width(this.textWidth)
      }

      if (isNil(graphicTextConfig.height)) {
        super.height(this.textHeight)
      }

      this.setTextAbsRectMatrix()

      requestAnimationFrame(() => {
        this.firstDrawIsComplete = true
        this.fire(EGraphicEvent.FIRST_DRAW_COMPLETE)
        graphicTextConfig.onFirstDraw?.(this)
      })
    })

    // 防抖重绘选区。
    this.#debouncedRedrawSelection = debounce(() => {
      this.setTextAbsRectMatrix()

      // 设置选区。
      if (this.isEditing) {
        this.#setSelectionByIndex(this.selectionIndexes, true)
      }
    }, INTERVAL)

    // 处理取消选中。
    this.on(EGraphicEvent.DESELECT, ({ silent }: { silent: boolean }) => {
      // 当取消选中时，退出编辑。
      if (this.isEditing) {
        this.exitEdit(silent)
      }
    })

    // 处理双击。
    this.on(EGraphicEvent.DOUBLE_CLICK, () => {
      if (this.isEditing) {
        // 双击全选文字。
        this.selectWord()
      } else if (this.inSingleSelect) {
        // 只有文本在未编辑状态下和被单选时双击才能编辑。
        this.startEdit('pointer', false)
      }
    })

    let isAdaptedHeight = false // 缩放前是否是自适应高度。
    let isAdaptedWidth = false // 缩放前是否是自适应宽度。

    // 处理开始缩放。
    this.on(EGraphicEvent.RESIZE_START, () => {
      if (this.transformer!.isResizeX) {
        // 当开始横向缩放时记录当前是否是自适应高度。
        isAdaptedHeight = this.isAdaptedHeight
        isAdaptedWidth = this.isAdaptedWidth
      }
    })

    // 处理正在缩放。
    this.on(EGraphicEvent.RESIZE, () => {
      const { attrs } = this.box

      // 同步边框属性。
      this.borderRect?.setAttrs(attrs)

      // 同步文字位置。
      this.graphic.position({ x: attrs.x, y: attrs.y })

      // 缩放时，只要有可能改变文字宽度，都需要重新设置文字矩阵。
      const theTextWidthMayChanged = !this.transformer?.keepRatio()
      if (theTextWidthMayChanged) {
        this.setTextMatrix()
      }

      if (this.transformer!.isResizeX && isAdaptedHeight) {
        // 当横向缩放时并且高度已经自适应时，需要同步高度。
        this.height(this.textHeight)
      } else if (
        this.transformer?.keepRatio() &&
        this.transformer.isResizeAngle
      ) {
        // 当等比并且是对角缩放时，需要同步文字缩放比。
        this.graphic.scale(this.box.scale())
      }
    })

    this.on(EGraphicEvent.RESIZE_END, () => {
      if (this.transformer?.keepRatio() && this.transformer.isResizeAngle) {
        // 当等比并且是对角缩放结束时，需要恢复文字缩放比，并调整字号大小。
        const scaleX = this.box.scaleX()
        this.textList.forEach((textItem) => {
          textItem.fontSize = textItem.fontSize! * scaleX
        })
        this.setTextMatrix()
        this.graphic.scale({ x: 1, y: 1 })

        // 恢复缩放比并同步内容尺寸。
        const { width, height } = this.hitActualRectBox
        this.box.setAttrs({
          width: this.graphicEditor!.actualSizeToLogicalSize(width),
          height: this.graphicEditor!.actualSizeToLogicalSize(height),
          scaleX: 1,
          scaleY: 1,
        })

        if (isAdaptedWidth) {
          this.width(this.textWidth)
        }

        if (isAdaptedHeight) {
          this.height(this.textHeight)
        }
      }
    })

    // 处理正在拖拽。
    this.on(EGraphicEvent.DRAG_MOVE, () => {
      const x = this.box.x()
      const y = this.box.y()

      // 同步边框位置。
      this.borderRect?.setAttrs({
        x,
        y,
      })

      // 时同步文本位置。
      this.graphic.setAttrs({
        x,
        y,
      })
    })

    // 处理图形选中状态变化。
    this.on(EGraphicEvent.GRAPHIC_SELECTION_CHANGE, () => {
      // 当选中多个图形时，如果自己是编辑状则退出编辑。
      if (this.isEditing && this.inMultiSelect) {
        this.exitEdit(false)
      }
    })
  }

  // 设置文本绝对位置，用来计算光标位置和选取色块。
  setTextAbsRectMatrix() {
    const {
      calcActualRectBox: { x, y },
    } = this

    this.textAbsRectMatrix = this.textMatrix.map((row) => {
      const maxHeight = max(row.map(({ lineHeightPX }) => lineHeightPX))!

      return row.map((text) => {
        return {
          width: roundTo(
            this.graphicEditor!.logicalSizeToActualSize(text.width),
          ),
          height: roundTo(
            this.graphicEditor!.logicalSizeToActualSize(maxHeight),
          ),
          x: roundTo(this.graphicEditor!.logicalSizeToActualSize(text.x) + x),
          y: roundTo(this.graphicEditor!.logicalSizeToActualSize(text.y) + y),
          index: text.index,
        }
      })
    })
  }

  // 初始化。
  init(cursor: 'pointer' | number[]) {
    // 设置输入框。
    this.$textarea = $textarea!
    this.$textarea.value = ''
    document.body.appendChild($textarea!)
    this.graphicEditor!.setCursorStyle('text')

    // 设置初始选区。
    if (cursor === 'pointer') {
      // 按照鼠标位置设置选区。
      const pointerPos = this.graphicEditor!.stage.getPointerPosition()!
      const cursor = this.getCursorByPointer({ pointerPos })!
      this.#setSelectionByCursor([cursor], true)
    } else {
      // 按照文本索引设置选区。
      this.#setSelectionByIndex(cursor, true)
    }

    // 监听编辑器尺寸变化。
    this.#listeners.push(
      this.on(EGraphicEvent.EDITOR_SIZE_CHANGE, () => {
        this.hideSelectionAndRedrawIfNeed()
      }),
    )

    // 当前移动选区索引。
    let curMoveIndex: Nullable<number>

    // 当前移动光标。
    let curMoveCursor: Nullable<ICursor>

    // 标记初始光标位置。
    this.#listeners.push(
      this.on(EGraphicEvent.MOUSE_DOWN, (e: KonvaEventObject<MouseEvent>) => {
        // 是否按下鼠标左键。
        const primaryButtonIsPressed = e.evt.button === 0
        if (!primaryButtonIsPressed) {
          return
        }

        // 鼠标位置。
        const pointerPos = this.graphicEditor!.stage.getPointerPosition()!

        // 设置选区光标开始位置。
        this.#mouseDownCursor = this.getCursorByPointer({ pointerPos })!
        if (this.#mouseDownCursor) {
          // 通过光标设置选区。
          this.#setSelectionByCursor([this.#mouseDownCursor], false)

          // 保存鼠标按下时的选区索引。
          this.#mouseDownIndexes = this.selectionIndexes

          // 重置当前移动选区索引。
          curMoveIndex = null

          // 重置当前移动光标。
          curMoveCursor = null
        } else {
          debugger
        }
      }),
    )

    // 处理鼠标弹起。
    const handleMouseUp = () => {
      if (!isEqual(this.#mouseDownIndexes, this.selectionIndexes)) {
        // 触发选区变化事件。
        this.fire(EGraphicEvent.TEXT_SELECTION_CHANGE, {
          preSelectionIndexes: this.#mouseDownIndexes,
          curSelectionIndexes: this.selectionIndexes,
        })
      }
    }

    this.graphicEditor!.stage.on(EGraphicEvent.MOUSE_UP, handleMouseUp)
    this.#listeners.push(() => {
      this.graphicEditor!.stage.off(EGraphicEvent.MOUSE_UP, handleMouseUp)
    })

    // 处理鼠标移动。
    const handleMouseMove = (e: KonvaEventObject<MouseEvent>) => {
      // 是否按下鼠标左键。
      const primaryButtonIsPressed = e.evt.buttons === 1
      if (!primaryButtonIsPressed) {
        return
      }

      // 获取鼠标位置。
      const pointerPos = this.graphicEditor!.stage.getPointerPosition()!

      // 获取当前光标位置。
      const curCursor = this.getCursorByPointer({ pointerPos })
      if (curCursor && this.#mouseDownCursor) {
        const cursors = this.sortSelectionCursor([
          this.#mouseDownCursor,
          curCursor,
        ])

        // 通过光标设置选区。
        this.#setSelectionByCursor(cursors, true)

        this.fire(EGraphicEvent.TEXT_SELECTING, this.selectionIndexes)
      }
    }

    this.graphicEditor!.stage.on(EGraphicEvent.MOUSE_MOVE, handleMouseMove)
    this.#listeners.push(() => {
      this.graphicEditor!.stage.off(EGraphicEvent.MOUSE_MOVE, handleMouseMove)
    })

    // 开始输入中文时的选区索引。
    let inputChineseSelectionIndexes: number[] = []

    // 需要删除的文本。
    let toDeleteText = ''

    // 开始输入中文时选中的文字列表。
    let compositionStartSelectedTextList: TextListItem[] = []

    // 触发文本变化事件。
    this.#fireTextChangingEvent = () => {
      const textChangeOps = mergeTextChangeOp(this.textChangeOps)
      if (textChangeOps.length > 0) {
        // 触发文本变化事件。
        this.fire(EGraphicEvent.TEXT_CHANGING, {
          textChangeOps,
        })

        this.textChangeOps = []
      }
    }

    // 节流处理文本变化。
    const handleTextChanging = throttle(this.#fireTextChangingEvent, INTERVAL, {
      leading: false,
      trailing: true,
    })

    this.#listeners.push(
      on(this.$textarea, 'input', (e: InputEvent) => {
        const {
          data: addedText,
          inputType,
          isComposing: isInputtingChinese,
        } = e

        // 关心的 input 类型。
        const CARE_INPUT_TYPES = ['insertText']

        if (
          addedText &&
          CARE_INPUT_TYPES.includes(inputType) &&
          !isInputtingChinese
        ) {
          // 设置修改类型。
          this.modifyType = EModifyType.INPUT

          // 添加文本操作记录。
          this.textChangeOps.push({
            indexes: this.selectionIndexes,
            deleteTextList: this.selectedTextList,
            addTextList: [
              {
                text: addedText,
                ...getTextStyleByIndex(
                  this.textList,
                  this.selectionStartIndex,
                  this.nextInsertTextStyle,
                ),
              },
            ],
          })

          // 修改文本。
          this.#modifyText(
            this.selectionStartIndex,
            this.selectedText,
            addedText,
          )

          // 处理文本变化。
          handleTextChanging()

          // 清空输入框内容，保证中文输入法输入框位置稳定。
          this.$textarea!.value = ''
        }
      }),
    )

    this.#listeners.push(
      on(this.$textarea, 'compositionstart', () => {
        // 设置初始选区索引。
        inputChineseSelectionIndexes = this.selectionIndexes

        // 当刚开始输入拼音时，如果有选中的文本需要删除掉。
        toDeleteText = this.selectedText
        compositionStartSelectedTextList = this.selectedTextList

        // 标记正在输入拼音。
        this.#isInputtingChinese = true
      }),
    )

    this.#listeners.push(
      on(this.$textarea, 'compositionupdate', (e) => {
        const { data: pinyinText } = e

        // 谷歌浏览器会在用户选择中文后会再触发一次 compositionupdate 事件，所以屏蔽掉。
        if (pinyinText.match(CHINESE_REG)) {
          return
        }

        // 设置修改类型。
        this.modifyType = EModifyType.INPUT_CHINESE

        // 修改文本。
        this.#modifyText(
          inputChineseSelectionIndexes[0],
          toDeleteText,
          pinyinText,
        )

        // 设置下次需要删除的文本。
        toDeleteText = pinyinText
      }),
    )

    this.#listeners.push(
      on(this.$textarea, 'compositionend', (e) => {
        const { data: chineseText } = e

        // 设置修改类型。
        this.modifyType = EModifyType.INPUT_CHINESE_END

        // 添加文本操作记录，这里需要注意，输入中文时的中间字母不能算作删除，所以需要删除掉。
        this.textChangeOps.push({
          indexes: inputChineseSelectionIndexes,
          deleteTextList: compositionStartSelectedTextList,
          addTextList: [
            {
              text: chineseText,
              ...getTextStyleByIndex(
                this.textList,
                inputChineseSelectionIndexes[0],
                this.nextInsertTextStyle,
              ),
            },
          ],
        })

        // 修改文本。
        this.#modifyText(
          inputChineseSelectionIndexes[0],
          toDeleteText,
          chineseText,
        )

        // 标记输入拼音结束。
        this.#isInputtingChinese = false

        // 处理文本变化。
        handleTextChanging()

        // 清空输入框内容，保证中文输入法输入框位置稳定。
        this.$textarea!.value = ''
      }),
    )

    this.#listeners.push(
      on(this.$textarea, 'paste', (e) => {
        const addedText = formatPastedContent(
          e.clipboardData?.getData('text/plain'),
        )
        if (addedText) {
          // 设置修改类型。
          this.modifyType = EModifyType.PASTE

          if (addedText === clipboardText) {
            // 添加文本操作记录。
            this.textChangeOps.push({
              indexes: this.selectionIndexes,
              deleteTextList: this.selectedTextList,
              addTextList: clipboardTextList,
            })

            // 修改文本。
            this.modifyText(
              this.selectionStartIndex,
              this.selectedTextList,
              clipboardTextList,
            )
          } else {
            // 添加文本操作记录。
            this.textChangeOps.push({
              indexes: this.selectionIndexes,
              deleteTextList: this.selectedTextList,
              addTextList: [
                {
                  text: addedText,
                  ...getTextStyleByIndex(
                    this.textList,
                    this.selectionStartIndex,
                    this.nextInsertTextStyle,
                  ),
                },
              ],
            })

            // 修改文本。
            this.#modifyText(
              this.selectionStartIndex,
              this.selectedText,
              addedText,
            )
          }

          // 处理文本变化。
          this.#fireTextChangingEvent()

          // 清空输入框内容，保证中文输入法输入框位置稳定，paste 事件触发时文本还没写入到输入框，所以需要延迟清空。
          setTimeout(() => {
            this.$textarea!.value = ''
          })

          this.fire(EGraphicEvent.PASTE_TEXT)
        }
      }),
    )

    // 监听输入框各种按键。
    this.#listeners.push(
      onKeyDown(this.$textarea, async (e) => {
        switch (getKeydownOp(e)) {
          // 回车，只所以不在 input 事件处理是发现输入英文后输入回车，inputType 不是 insertLineBreak，不知道为什么，所以只能通过按键插入回车。
          case EKeyboardOperation.ENTER: {
            // 正在输入拼音时，不处理回车。
            if (this.#isInputtingChinese) {
              return
            }

            // 设置修改类型。
            this.modifyType = EModifyType.ENTER

            // 添加文本操作记录。
            this.textChangeOps.push({
              indexes: this.selectionIndexes,
              deleteTextList: this.selectedTextList,
              addTextList: [
                {
                  text: '\n',
                  ...getTextStyleByIndex(
                    this.textList,
                    this.selectionStartIndex,
                    this.nextInsertTextStyle,
                  ),
                },
              ],
            })

            // 新增回车。
            this.#modifyText(this.selectionStartIndex, this.selectedText, '\n')

            // 处理文本变化。
            handleTextChanging()

            break
          }

          // 删除。
          case EKeyboardOperation.DELETE: {
            // 当前选中文本。
            const selectedText = this.selectedText

            // 如果没有选中文本并且选区开始索引为 0，则不处理。
            if (!selectedText && this.selectionStartIndex === 0) {
              return
            }

            // 设置修改类型。
            this.modifyType = EModifyType.DELETE

            if (selectedText) {
              // 添加文本操作记录。
              this.textChangeOps.push({
                indexes: this.selectionIndexes,
                deleteTextList: this.selectedTextList,
                addTextList: [],
              })

              // 删除选中文本。
              this.#modifyText(this.selectionStartIndex, selectedText, '')
            } else if (this.selectionStartIndex > 0) {
              // 添加文本操作记录。
              this.textChangeOps.push({
                indexes: [
                  this.selectionStartIndex - 1,
                  this.selectionStartIndex,
                ],
                deleteTextList: this.getTextListByIndexes(
                  this.selectionStartIndex - 1,
                  this.selectionStartIndex,
                ),
                addTextList: [],
              })

              // 删除光标前一个字符。
              this.#modifyText(
                this.selectionStartIndex - 1,
                this.textList[this.selectionStartIndex - 1].text,
                '',
              )
            }

            // 处理文本变化。
            handleTextChanging()

            break
          }

          // 复制。
          case EKeyboardOperation.COPY: {
            if (this.selectedText) {
              clipboardTextList = this.selectedTextList
              clipboardText = this.selectedText

              // 复制文本。
              copy(this.selectedText)
            }

            break
          }

          // 剪切。
          case EKeyboardOperation.CUT: {
            if (!this.selectedText) {
              return
            }

            // 设置修改类型。
            this.modifyType = EModifyType.DELETE

            clipboardTextList = this.selectedTextList
            clipboardText = this.selectedText

            // 复制文本。
            copy(this.selectedText)

            // 添加文本操作记录。
            this.textChangeOps.push({
              indexes: this.selectionIndexes,
              deleteTextList: this.selectedTextList,
              addTextList: [],
            })

            // 删除文本。
            this.#modifyText(this.selectionStartIndex, this.selectedText, '')

            // 处理文本变化。
            handleTextChanging()

            break
          }

          // 向左移动光标。
          case EKeyboardOperation.LEFT: {
            if (this.isSelectedText) {
              // 如果当前已经选中文本，则光标移动到选区开始位置。
              this.#setSelectionByIndex([this.selectionStartIndex], false)
            } else {
              // 如果光标已经在最左边了，则不处理。
              if (this.selectionStartIndex === 0) {
                return
              }

              // 选区索引向左移动。
              this.#setSelectionByIndex([this.selectionStartIndex - 1], false)
            }

            // 设置当前移动选区索引为选区开始索引。
            curMoveIndex = this.selectionStartIndex

            // 设置当前移动光标为选区开始光标。
            curMoveCursor = this.selectionStartCursor

            break
          }

          // 向左选中文本。
          case EKeyboardOperation.SELECT_LEFT: {
            // 如果光标已经在最左边了，则不处理。
            if (
              curMoveIndex === this.selectionStartIndex &&
              curMoveIndex === 0
            ) {
              return
            }

            if (
              isNil(curMoveIndex) ||
              curMoveIndex === this.selectionStartIndex
            ) {
              // 选区开始索引向左移动。
              curMoveIndex = this.selectionStartIndex - 1

              // 通过索引设置选区。
              this.#setSelectionByIndex(
                [curMoveIndex, this.selectionEndIndex],
                false,
              )

              // 设置当前移动光标为选区开始光标。
              curMoveCursor = this.selectionStartCursor
            } else {
              // 选区结束索引向左移动。
              curMoveIndex = this.selectionEndIndex - 1

              // 通过索引设置选区。
              this.#setSelectionByIndex(
                [this.selectionStartIndex, curMoveIndex],
                false,
              )

              // 设置当前移动光标为选区结束光标。
              curMoveCursor = this.selectionEndCursor
            }

            break
          }

          // 向右移动光标。
          case EKeyboardOperation.RIGHT: {
            if (this.isSelectedText) {
              // 如果当前已经选中文本，则光标移动到选区结束位置。
              this.#setSelectionByIndex([this.selectionEndIndex], false)
            } else {
              // 如果光标已经在最右边了，则不处理。
              if (this.selectionEndIndex === this.textLength) {
                return
              }

              // 选区索引向右移动。
              this.#setSelectionByIndex([this.selectionStartIndex + 1], false)
            }

            // 设置当前移动选区索引为选区开始索引。
            curMoveIndex = this.selectionStartIndex

            // 设置当前移动光标为选区开始光标。
            curMoveCursor = this.selectionStartCursor

            break
          }

          // 向右选中文本。
          case EKeyboardOperation.SELECT_RIGHT: {
            // 如果光标已经在最右边了，则不处理。
            if (
              curMoveIndex === this.selectionEndIndex &&
              curMoveIndex === this.textLength
            ) {
              return
            }

            if (
              isNil(curMoveIndex) ||
              curMoveIndex === this.selectionEndIndex
            ) {
              // 选区结束索引向右移动。
              curMoveIndex = this.selectionEndIndex + 1

              // 通过索引设置选区。
              this.#setSelectionByIndex(
                [this.selectionStartIndex, curMoveIndex],
                false,
              )

              // 设置当前移动的光标为选区结束光标。
              curMoveCursor = this.selectionEndCursor
            } else {
              // 选区开始索引向右移动。
              curMoveIndex = this.selectionStartIndex + 1

              // 通过索引设置选区。
              this.#setSelectionByIndex(
                [curMoveIndex, this.selectionEndIndex],
                false,
              )

              // 设置当前移动的光标为选区开始光标。
              curMoveCursor = this.selectionStartCursor
            }

            break
          }

          // 向上移动光标。
          case EKeyboardOperation.UP: {
            // 如果光标已经在最上边了，则不处理。
            if (this.selectionStartCursor.row === 0) {
              return
            }

            // 光标垂直移动到上一行。
            const cursor = this.moveCursorVertical({
              cursor: this.selectionStartCursor,
              direction: ECursorMoveDirection.UP,
              lines: 1,
            })!

            // 通过光标设置选区。
            this.#setSelectionByCursor([cursor], false)

            // 设置当前移动的选区索引为选区开始索引。
            curMoveIndex = this.selectionStartIndex

            // 设置当前移动的光标为选区开始光标。
            curMoveCursor = this.selectionStartCursor

            break
          }

          // 向上选中文本。
          case EKeyboardOperation.SELECT_UP: {
            // 如果光标已经在最上边了，则不处理。
            if (
              isNil(curMoveCursor)
                ? this.selectionStartCursor.row === 0
                : curMoveCursor.row === 0
            ) {
              return
            }

            if (
              isNil(curMoveCursor) ||
              isEqual(curMoveCursor, this.selectionStartCursor)
            ) {
              // 开始光标垂直移动到上一行。
              const cursor = this.moveCursorVertical({
                cursor: this.selectionStartCursor,
                direction: ECursorMoveDirection.UP,
                lines: 1,
              })!

              // 排序选区光标。
              const cursors = this.sortSelectionCursor([
                cursor,
                this.selectionEndCursor,
              ])

              // 通过光标设置选区。
              this.#setSelectionByCursor(cursors, false)

              // 设置当前移动的选区索引为选区开始索引。
              curMoveIndex = this.selectionStartIndex

              // 设置当前移动的光标为选区开始光标。
              curMoveCursor = this.selectionStartCursor
            } else {
              // 结束光标垂直移动到上一行。
              const cursor = this.moveCursorVertical({
                cursor: this.selectionEndCursor,
                direction: ECursorMoveDirection.UP,
                lines: 1,
              })!

              // 排序选区光标。
              const cursors = this.sortSelectionCursor([
                this.selectionStartCursor,
                cursor,
              ])

              // 通过光标设置选区。
              this.#setSelectionByCursor(cursors, false)

              // 移动后的光标是否变成开始光标。
              const isStartCursor = isEqual(cursor, cursors[0])

              // 根据当前移动后的光标的位置设置当前移动选区索引。
              curMoveIndex = isStartCursor
                ? this.selectionStartIndex
                : this.selectionEndIndex

              // 根据当前移动后的光标的位置设置当前移动光标。
              curMoveCursor = isStartCursor
                ? this.selectionStartCursor
                : this.selectionEndCursor
            }

            break
          }

          // 向下移动光标。
          case EKeyboardOperation.DOWN: {
            // 如果光标已经在最下边了，则不处理。
            if (
              isNil(curMoveCursor)
                ? this.selectionStartCursor.row === this.textMatrix.length - 1
                : curMoveCursor.row === this.textMatrix.length - 1
            ) {
              return
            }

            // 光标垂直移动到下一行。
            const cursor = this.moveCursorVertical({
              cursor: this.selectionStartCursor,
              direction: ECursorMoveDirection.DOWN,
              lines: 1,
            })!

            // 通过光标设置选区。
            this.#setSelectionByCursor([cursor], false)

            // 设置当前移动的选区索引为选区开始索引。
            curMoveIndex = this.selectionStartIndex

            // 设置当前移动的光标为选区开始光标。
            curMoveCursor = this.selectionStartCursor

            break
          }

          // 向下选中文本。
          case EKeyboardOperation.SELECT_DOWN: {
            // 如果光标已经在最下边了，则不处理。
            if (
              isNil(curMoveCursor)
                ? this.selectionEndCursor.row === this.textMatrix.length - 1
                : curMoveCursor.row === this.textMatrix.length - 1
            ) {
              return
            }

            if (
              isNil(curMoveCursor) ||
              isEqual(curMoveCursor, this.selectionEndCursor)
            ) {
              // 结束光标垂直移动到下一行。
              const cursor = this.moveCursorVertical({
                cursor: this.selectionEndCursor,
                direction: ECursorMoveDirection.DOWN,
                lines: 1,
              })!

              // 排序选区光标。
              const cursors = this.sortSelectionCursor([
                this.selectionStartCursor,
                cursor,
              ])

              // 通过光标设置选区。
              this.#setSelectionByCursor(cursors, false)

              // 设置当前移动的选区索引为选区结束索引。
              curMoveIndex = this.selectionEndIndex

              // 设置当前移动的光标为选区结束光标。
              curMoveCursor = this.selectionEndCursor
            } else {
              // 开始光标垂直移动到下一行。
              const cursor = this.moveCursorVertical({
                cursor: this.selectionStartCursor,
                direction: ECursorMoveDirection.DOWN,
                lines: 1,
              })!

              // 排序选区光标。
              const cursors = this.sortSelectionCursor([
                cursor,
                this.selectionEndCursor,
              ])

              // 通过光标设置选区。
              this.#setSelectionByCursor(cursors, false)

              // 移动后的光标是否变成开始光标。
              const isStartCursor = isEqual(cursor, cursors[0])

              // 根据当前移动后的光标的位置设置当前移动选区索引。
              curMoveIndex = isStartCursor
                ? this.selectionStartIndex
                : this.selectionEndIndex

              // 根据当前移动后的光标的位置设置当前移动光标。
              curMoveCursor = isStartCursor
                ? this.selectionStartCursor
                : this.selectionEndCursor
            }

            break
          }

          // 全选。
          case EKeyboardOperation.ALL: {
            // 通过光标设置选区。
            this.#setSelectionByIndex([0, this.textList.length], false)

            break
          }

          default: {
            break
          }
        }
      }),
    )

    // 阻止 部分事件 冒泡。
    const preventOrStopKeyboardEvent = (e: KeyboardEvent) => {
      // 阻止默认行为。
      if (
        checkKeydownOp(e, EKeyboardOperation.UNDO) ||
        checkKeydownOp(e, EKeyboardOperation.REDO)
      ) {
        e.preventDefault()

        // 当撤销时发现文本已经修改。
        // 说明 interval 间隔还没到，此时如果按下了撤销，主动触发一次文本变化事件。
        if (
          checkKeydownOp(e, EKeyboardOperation.UNDO) &&
          this.hasUnrecordedTextOp
        ) {
          this.#fireTextChangingEvent()
        }
      }

      // 阻止事件冒泡。
      if (checkKeydownOp(e, EKeyboardOperation.PASTE)) {
        e.stopPropagation()
      }

      // 其他行为都阻止默认行为和冒泡。
      if (
        checkKeydownOp(e, EKeyboardOperation.DELETE) ||
        checkKeydownOp(e, EKeyboardOperation.COPY) ||
        checkKeydownOp(e, EKeyboardOperation.CUT) ||
        checkKeydownOp(e, EKeyboardOperation.LEFT) ||
        checkKeydownOp(e, EKeyboardOperation.RIGHT) ||
        checkKeydownOp(e, EKeyboardOperation.UP) ||
        checkKeydownOp(e, EKeyboardOperation.DOWN) ||
        checkKeydownOp(e, EKeyboardOperation.ALL)
      ) {
        e.preventDefault()
        e.stopPropagation()
      }
    }

    // 监听输入框 keydown 事件。
    this.#listeners.push(onKeyDown(this.$textarea!, preventOrStopKeyboardEvent))

    // 监听输入框 keyup 事件。
    this.#listeners.push(onKeyUp(this.$textarea!, preventOrStopKeyboardEvent))
  }

  // 对外暴露的修改文字方法。
  modifyText(
    index: number,
    deleteTextList: TextListItem[],
    addTextList: TextListItem[],
  ) {
    const { selfAdaptIfNeed } = this.useSizeSelfAdapter()

    // 扁平化待添加的文本列表。
    const flattenedAddTextList = flatTextList(addTextList)

    // 扁平化待删除的文本列表。
    const flattenedDeleteTextList = flatTextList(deleteTextList)

    // 加载字体，之后重新绘制文字。
    this.loadTextListFont(addTextList).then(() => {
      this.redrawText()
    })

    // 修改文字。
    this.textList.splice(
      index,
      flattenedDeleteTextList.length,
      ...flattenedAddTextList,
    )

    // 重绘文字。
    this.redrawText()

    // 调整光标位置。
    if (flattenedAddTextList.length > 0) {
      this.#setSelectionByIndex([index + flattenedAddTextList.length], true)
    } else {
      this.#setSelectionByIndex([index], true)
    }

    // 调整自适应。
    selfAdaptIfNeed()

    this.fire(EGraphicEvent.TEXT_CHANGE)
  }

  // 修改文字。
  #modifyText(index: number, deleteText: string, addText: string) {
    const { selfAdaptIfNeed } = this.useSizeSelfAdapter()

    // 待添加的文字列表。
    const textStyle = getTextStyleByIndex(
      this.textList,
      index,
      this.nextInsertTextStyle,
    )
    const addTextList = toArray(addText).map((text) => {
      return {
        text,
        ...textStyle,
      }
    })

    // 加载字体，之后重新绘制文字。
    this.loadTextListFont(addTextList).then(() => {
      this.redrawText()
    })

    // 取第一个字符。
    const firstText = first(this.textList)!

    // 修改文字。
    this.textList.splice(index, toArray(deleteText).length, ...addTextList)

    // 如果修改后，一个文字都没有，则增加本来的一个字符串并请空内容，否则无法继续输入。
    if (this.textList.length === 0) {
      firstText.text = ''
      this.textList.push(firstText)
    }

    // 重绘文字。
    this.redrawText()

    // 调整光标位置。
    if (addTextList.length > 0) {
      this.#setSelectionByIndex([index + addTextList.length], true)
    } else {
      this.#setSelectionByIndex([index], true)
    }

    // 调整自适应。
    selfAdaptIfNeed()

    this.fire(EGraphicEvent.TEXT_CHANGE)
  }

  // 单词列表，已空白符分割。
  get words() {
    const textArr = this.textList.map(({ text }) => text)
    const words: string[] = []
    let word = ''
    for (let i = 0; i < textArr.length; i++) {
      const text = textArr[i]
      if (text.match(/^\s$/)) {
        if (!word || word.match(/\s/g)) {
          word += text
        } else {
          words.push(word)
          word = text
        }
      } else {
        if (word.match(/\s/g)) {
          words.push(word)
          word = text
        } else {
          word += text
        }
      }
    }

    if (word.length > 0) {
      words.push(word)
    }

    return words
  }

  // 选中的词。
  selectWord() {
    const charIndex = this.getSelectionIndexByCursor(this.#mouseDownCursor!)

    const { pos } = this.#mouseDownCursor!

    const words = this.words

    let index = 0
    for (let j = 0; j < words.length; j++) {
      const wordLength = toArray(words[j]).length
      if (
        charIndex >= index &&
        (charIndex < index + wordLength ||
          (charIndex === index + wordLength && pos === 'right'))
      ) {
        this.#setSelectionByIndex([index, index + wordLength], false)

        return
      }

      index += wordLength
    }
  }

  // 排序选区光标，保证小的在前，大的在后。
  sortSelectionCursor(cursors: ICursor[]) {
    // 开始光标。
    let startCursor: ICursor

    // 结束光标。
    let endCursor: ICursor

    if (cursors[0].row < cursors[1].row) {
      startCursor = cursors[0]
      endCursor = cursors[1]
    } else if (cursors[0].row > cursors[1].row) {
      startCursor = cursors[1]
      endCursor = cursors[0]
    } else {
      if (cursors[0].col < cursors[1].col) {
        startCursor = cursors[0]
        endCursor = cursors[1]
      } else if (cursors[0].col > cursors[1].col) {
        startCursor = cursors[1]
        endCursor = cursors[0]
      } else {
        if (
          cursors[0].pos === ECursorSide.LEFT &&
          cursors[1].pos === ECursorSide.RIGHT
        ) {
          startCursor = cursors[0]
          endCursor = cursors[1]
        } else if (
          cursors[0].pos === ECursorSide.RIGHT &&
          cursors[1].pos === ECursorSide.LEFT
        ) {
          startCursor = cursors[1]
          endCursor = cursors[0]
        } else {
          startCursor = cursors[0]
          endCursor = cursors[1]
        }
      }
    }

    return [startCursor, endCursor]
  }

  /**
   * 根据文本索引获取光标位置
   * 光标的位置逻辑：
   * 1、索引为 0 代表光标在第一个字符的左侧
   * 2、索引不为 0 代表光标在对应字符的右侧，如果一个字符不是整个文本的最后一个字符并且是一行的最后一个字符，那么光标在下一行的左侧。
   */
  getCursorByIndex(index: number) {
    // 第一个字符左侧。
    if (index === 0) {
      return {
        row: 0,
        col: 0,
        pos: ECursorSide.LEFT,
      }
    }

    for (let row = 0; row < this.textMatrix.length; row++) {
      const textInfoList = this.textMatrix[row]

      // 是否是最后一行。
      const isLastLine = row === this.textMatrix.length - 1

      for (let col = 0; col < textInfoList.length; col++) {
        if (textInfoList[col].index === index - 1) {
          // 是否是最后一列。
          const isLastCol = col === textInfoList.length - 1

          if (isLastCol && !isLastLine) {
            // 当前是一行最后一个字符，但是下一行还有字符，则光标在下一行的左侧。
            return this.correctCursor({
              row: row + 1,
              col: 0,
              pos: ECursorSide.LEFT,
            })
          }

          // 光标在字符的右侧。
          return this.correctCursor({
            row,
            col,
            pos: ECursorSide.RIGHT,
          })
        }
      }
    }

    // 最后一个字符。
    return this.correctCursor({
      row: this.textMatrix.length - 1,
      col: this.textMatrix[this.textMatrix.length - 1].length - 1,
      pos: ECursorSide.RIGHT,
    })
  }

  // 移除选中文本色块。
  removeSelectionColorRect() {
    this.hideSelectionColorRectIfNeed()
    this.#selectionColorRectList = []
  }

  // 隐藏选中文本色块。
  hideSelectionColorRectIfNeed() {
    this.#selectionColorRectList.forEach((colorRect) => {
      colorRect.remove()
    })
  }

  // 在垂直方向移动光标。
  moveCursorVertical({
    cursor,
    direction,
    lines = 1,
  }: {
    cursor: ICursor
    direction: ECursorMoveDirection
    lines: number
  }) {
    const { col, row, pos } = cursor

    let { x, y } = this.textAbsRectMatrix[row][col]
    const { height, width } = this.textAbsRectMatrix[row][col]
    if (pos === ECursorSide.RIGHT) {
      x = x + width
    }

    let movedCursor: ICursor

    // 手动模仿鼠标位置，在当前光标位置垂直方向移动 lines 个行高的距离。
    if (ECursorMoveDirection.UP === direction) {
      y -= height * lines

      movedCursor = this.getCursorByPointer({
        pointerPos: { x, y },
        startRow: row - lines,
        endRow: row - lines,
      })!
    } else {
      y += height * lines

      movedCursor = this.getCursorByPointer({
        pointerPos: { x, y },
        startRow: row + lines,
        endRow: row + lines,
      })!
    }

    // 纠正光标位置。
    return this.correctCursor(movedCursor!)
  }

  // 纠正鼠标光标。
  correctCursor(cursor: ICursor) {
    const { text } = this.textMatrix[cursor.row][cursor.col]
    if (text === '\n') {
      if (this.textMatrix[cursor.row].length === 1) {
        // 一行只有一个回车，则光标需在左侧。
        cursor.pos = ECursorSide.LEFT
      } else {
        // 否则光标移动左侧字符的右侧。
        cursor.col -= 1
        cursor.pos = ECursorSide.RIGHT
      }
    } else if (text === '') {
      // 当前是空字符，则光标需在左侧。
      cursor.pos = ECursorSide.LEFT
    }

    return cursor
  }

  // 根据鼠标位置获取光标。
  getCursorByPointer({
    pointerPos,
    startRow,
    endRow,
  }: {
    pointerPos: IPos
    startRow?: number
    endRow?: number
  }) {
    // 鼠标矩形。
    const pointerRect = {
      ...pointerPos,
      width: 1,
      height: 1,
    }

    // 开始行。
    if (isNil(startRow)) {
      startRow = 0
    } else {
      startRow = Math.max(
        Math.min(startRow, this.textAbsRectMatrix.length - 1),
        0,
      )
    }

    // 结束行。
    if (isNil(endRow)) {
      endRow = this.textAbsRectMatrix.length - 1
    } else {
      endRow = Math.max(Math.min(endRow, this.textAbsRectMatrix.length - 1), 0)
    }

    for (let row = startRow; row <= endRow; row++) {
      const textInfoList = this.textAbsRectMatrix[row]

      // 是否是第一行。
      const isFirstLine = row === 0

      // 是否是最后一行。
      const isLastLine = row === this.textAbsRectMatrix.length - 1
      for (let col = 0; col < textInfoList.length; col++) {
        // 是否是第一列。
        const isFirstCol = col === 0

        // 是否是最后一列。
        const isLastCol = col === this.textAbsRectMatrix[row].length - 1

        // 文本信息。
        const textInfo = textInfoList[col]

        // 文本尺寸。
        const textRect = pick(textInfo, ['x', 'y', 'width', 'height'])

        // 解决光标。
        const resolveCursor = () => {
          const cursor = {
            row,
            col,
            pos: ECursorSide.LEFT,
          }

          if (pointerPos.x >= textInfo.x + textInfo.width / 2) {
            // 鼠标在字的右侧。
            cursor.pos = ECursorSide.RIGHT
          }

          return this.correctCursor(cursor)
        }

        // 判断文本和鼠标是否有交集。
        if (Util.haveIntersection(textRect, pointerRect)) {
          return resolveCursor()
        }

        // 大数，用来比较鼠标和文本是否有交集。
        const BIG_NUMBER = 9999

        // 第一列，且鼠标在第一列左侧。
        if (isFirstCol && pointerPos.x < textRect.x) {
          textRect.width = BIG_NUMBER
          textRect.x -= textRect.width
          if (Util.haveIntersection(textRect, pointerRect)) {
            return resolveCursor()
          }
        }

        // 第一行，且鼠标在第一行上侧。
        if (isFirstLine && pointerPos.y < textRect.y) {
          textRect.height = BIG_NUMBER
          textRect.y -= textRect.height
          if (Util.haveIntersection(textRect, pointerRect)) {
            return resolveCursor()
          }
        }

        // 最后一列，且鼠标在最后一列右侧。
        if (isLastCol && pointerPos.x > textRect.x + textRect.width) {
          textRect.width = BIG_NUMBER
          if (Util.haveIntersection(textRect, pointerRect)) {
            return resolveCursor()
          }
        }

        // 最后一行，且鼠标在最后一行下侧。
        if (isLastLine && pointerPos.y > textRect.y + textRect.height) {
          textRect.height = BIG_NUMBER
          if (Util.haveIntersection(textRect, pointerRect)) {
            return resolveCursor()
          }
        }
      }
    }
  }

  // 隐藏光标。
  hideCursor() {
    if (this.$textarea) {
      Object.assign(this.$textarea.style, {
        opacity: 0,
        animation: 'none',
      })
    }
  }

  // 隐藏选区。
  hideSelection() {
    if (this.isSelectedText) {
      this.removeSelectionColorRect()
    } else {
      this.hideCursor()
    }
  }

  // 光标是否已隐藏。
  get cursorIsHide() {
    return !this.$textarea || !this.$textarea.style.opacity
  }

  // 取消光标闪烁。
  cancelBlinkCursor() {
    if (this.$textarea) {
      Object.assign(this.$textarea.style, {
        animation: 'none',
      })
    }
  }

  // 闪烁光标。
  blinkCursor() {
    if (this.$textarea) {
      Object.assign(this.$textarea.style, {
        animation: 'blink 1s infinite',
      })
    }
  }

  // 显示光标。
  showCursor() {
    if (this.$textarea) {
      Object.assign(this.$textarea.style, {
        opacity: 1,
      })
    }
  }

  // 获取光标实际位置。
  getCursorActualRect(cursor: ICursor) {
    // 光标高度占文本高度的比例。
    const CURSOR_HEIGHT_SCALE = 0.75

    const { offsetLeft, offsetTop } = this.graphicEditor!.container!

    // 光标所在字符的位置和大小。
    const text = { ...this.textAbsRectMatrix[cursor.row][cursor.col] }
    if (cursor.pos === ECursorSide.RIGHT) {
      text.x += text.width
    }

    // 光标 x 轴位置。
    const x = text.x + offsetLeft

    // 光标 y 轴位置。
    const y = text.y + offsetTop + (text.height * (1 - CURSOR_HEIGHT_SCALE)) / 2

    // 光标高度。
    const height = text.height * CURSOR_HEIGHT_SCALE

    return {
      x,
      y,
      height,
    }
  }

  // 选区开始光标。
  get selectionStartCursor() {
    return this.selectionCursors[0]
  }

  // 选区结束光标。
  get selectionEndCursor() {
    return this.selectionCursors[1]
  }

  // 通过光标获取选区索引。
  getSelectionIndexByCursor(cursor: ICursor) {
    try {
      const { row, col, pos } = cursor

      if (pos === ECursorSide.LEFT) {
        if (col === 0) {
          const preLineLastText = last(this.textMatrix[row - 1])
          if (preLineLastText && preLineLastText.text === '\n') {
            return preLineLastText.index + 1
          }
        }

        return this.textMatrix[row][col].index
      }

      return this.textMatrix[row][col]?.index + 1
    } catch (err) {
      console.error(err, cloneDeep(cursor), cloneDeep(this.textMatrix))

      return 0
    }
  }

  // 通过文本索引设置选区。
  setSelectionByIndex(indexes: number[]) {
    this.#setSelectionByIndex(indexes, true)
  }

  // 清理下一次输入的文本的样式，如果需要的话。
  clearNextTextStyleIfNeed() {
    if (!this.#isInputtingChinese) {
      this.nextInsertTextStyle = {}
    }
  }

  // 通过文本索引设置选区，内部函数。
  #setSelectionByIndex(indexes: number[], silent: boolean) {
    // 新的选区索引。
    const newSelectionIndexes = [indexes[0], indexes[1] ?? indexes[0]]

    // 旧的选区索引。
    const oldSelectionIndexes = this.selectionIndexes

    // 设置新的选区索引。
    this.selectionIndexes = newSelectionIndexes
    this.selectionStartIndex = this.selectionIndexes[0]
    this.selectionEndIndex = this.selectionIndexes[1]

    // 设置新的光标。
    const endCursor = this.getCursorByIndex(this.selectionEndIndex)
    if (this.selectionStartIndex === this.selectionEndIndex) {
      this.selectionCursors = [endCursor, endCursor]
    } else {
      const startCursor = this.getCursorByIndex(this.selectionStartIndex)
      this.selectionCursors = [startCursor, endCursor]
    }

    // 选区是否有变化。
    const isChanged = !isEqual(newSelectionIndexes, oldSelectionIndexes)
    if (!silent && isChanged) {
      // 触发选区变化事件。
      this.fire(EGraphicEvent.TEXT_SELECTION_CHANGE, {
        preSelectionIndexes: oldSelectionIndexes,
        curSelectionIndexes: this.selectionIndexes,
      })
    }

    if (isChanged) {
      this.clearNextTextStyleIfNeed()
    }

    // 绘制选区。
    this.drawSelection()
  }

  // 通过光标设置选区。
  #setSelectionByCursor(cursors: ICursor[], silent: boolean) {
    // 新的光标。
    const newSelectionCursors = [cursors[0], cursors[1] ?? cursors[0]]

    // 旧的光标。
    const oldSelectionCursors = this.selectionCursors

    // 旧的选区索引。
    const oldSelectionIndexes = this.selectionIndexes

    // 设置新的光标。
    this.selectionCursors = newSelectionCursors

    // 光标是否有变化。
    const isChanged = !isEqual(newSelectionCursors, oldSelectionCursors)
    if (isChanged) {
      // 设置新的选区索引。
      this.selectionIndexes = [
        this.getSelectionIndexByCursor(this.selectionCursors[0]),
        this.getSelectionIndexByCursor(this.selectionCursors[1]),
      ]
      this.selectionStartIndex = this.selectionIndexes[0]
      this.selectionEndIndex = this.selectionIndexes[1]

      if (!silent) {
        // 触发选区变化事件。
        this.fire(EGraphicEvent.TEXT_SELECTION_CHANGE, {
          preSelectionIndexes: oldSelectionIndexes,
          curSelectionIndexes: this.selectionIndexes,
        })
      }
    }

    if (isChanged) {
      this.clearNextTextStyleIfNeed()
    }

    // 绘制选区。
    this.drawSelection()
  }

  // 绘制选区色块。
  drawSelectionColorRect() {
    // 是否只选中了一行。
    const isSelectOneLine =
      this.selectionStartCursor.row === this.selectionEndCursor.row

    const { calcActualRectBox } = this

    // 选区矩形列表。
    const selectionRectList = []
    for (
      let row = this.selectionStartCursor.row;
      row <= this.selectionEndCursor.row;
      row++
    ) {
      // 是否是第一行。
      const isFirstLine = row === this.selectionStartCursor.row

      // 是否是最后一行。
      const isLastLine = row === this.selectionEndCursor.row

      // 一行开始列。
      const startCol = isFirstLine ? this.selectionStartCursor.col : 0

      // 一行结束列。
      const endCol = isLastLine
        ? this.selectionEndCursor.col
        : this.textAbsRectMatrix[row].length - 1

      // 一行文本列表。
      const textList = this.textAbsRectMatrix[row].slice(startCol, endCol + 1)

      // 一行色块 x 值。
      let x = this.textAbsRectMatrix[row][startCol].x

      // 一行的色块宽度。
      let width = 0

      // 一行第一个文本。
      const firstText = first(textList)!

      // 一行最后一个文本。
      const lastText = last(textList)!

      if (isSelectOneLine) {
        width = textList.reduce((total, { width }) => total + width, 0)

        // 如果开始光标是右侧，则去除掉第一个文本。
        if (this.selectionStartCursor.pos === ECursorSide.RIGHT) {
          width -= firstText.width
          x += firstText.width
        }

        // 如果结束光标是左侧，则去除掉最后一个文本。
        if (this.selectionEndCursor.pos === ECursorSide.LEFT) {
          width -= lastText.width
        }
      } else {
        if (isFirstLine) {
          // 第一行的色块从第一个文本开始到文本最右侧。
          width = calcActualRectBox.x2 - firstText.x

          // 如果第一行的第一列光标在右侧，则去除掉第一个文本。
          if (this.selectionStartCursor.pos === ECursorSide.RIGHT) {
            width -= firstText.width
            x += firstText.width
          }
        } else if (isLastLine) {
          // 最后一行的色块从文本最左侧到最后一个文本。
          width = lastText.x - calcActualRectBox.x

          // 如果最后一行的最后一列光标在左侧，则去除掉最后一个文本。
          if (this.selectionEndCursor.pos === ECursorSide.RIGHT) {
            width += lastText.width
          }

          x = calcActualRectBox.x
        } else {
          // 不是第一行也不是最后一行，则色块占满这个文本。
          width = calcActualRectBox.width
          x = calcActualRectBox.x
        }
      }

      selectionRectList.push({
        x,
        y: this.textAbsRectMatrix[row][startCol].y,
        width,
        height: max(textList.map((t) => t.height)),
      })
    }

    // 创建选区色块。
    this.#selectionColorRectList = selectionRectList.map((rect, i) => {
      let konvaRect = this.#selectionColorRectList[i]
      if (konvaRect) {
        konvaRect.setAttrs(rect)
      } else {
        konvaRect = new KonvaRect({
          ...rect,
          name: 'selectionColorRect',
          fill: this.graphicEditor!.primaryColor,
          opacity: 0.5,
        })
      }

      this.graphicEditor!.graphicGroup.display.add(konvaRect)

      return konvaRect
    })
  }

  // 设置光标颜色。
  setCursorColor(color: string) {
    if (this.$textarea) {
      Object.assign(this.$textarea.style, {
        color,
        background: color,
        caretColor: color,
      })
    }
  }

  // 设置光标颜色。
  setCursorPosition() {
    if (this.$textarea) {
      const { x, y, height } = this.getCursorActualRect(
        this.selectionStartCursor,
      )
      Object.assign(this.$textarea.style, {
        left: `${x}px`,
        top: `${y}px`,
        height: `${height}px`,
      })
    }
  }

  // 设置光标大小。
  setCursorSize() {
    if (this.$textarea) {
      // 编辑器和光标相交区域。
      const {
        offsetLeft: x,
        offsetTop: y,
        offsetWidth: width,
        offsetHeight: height,
      } = this.graphicEditor!.container!
      const intersectionArea = calculateIntersectionArea(
        {
          ...this.getCursorActualRect(this.selectionStartCursor),
          width: 1,
        },
        {
          x,
          y,
          width,
          height,
        },
      )
      Object.assign(this.$textarea.style, {
        width: `${intersectionArea.width}px`,
        height: `${intersectionArea.height}px`,
      })
    }
  }

  // 绘制光标。
  drawCursor() {
    // 取消光标闪烁。
    this.cancelBlinkCursor()

    // 设置光标位置。
    this.setCursorPosition()

    // 设置光标颜色。
    const { fill, fillPriority } = getTextStyleByIndex(
      this.textList,
      this.selectionStartIndex,
    )
    switch (fillPriority) {
      case EFillPriority.COLOR: {
        this.setCursorColor(fill)

        break
      }

      case EFillPriority.LINEAR_GRADIENT: {
        this.setCursorColor('black')

        break
      }

      default: {
        break
      }
    }

    // 设置光标大小。
    this.setCursorSize()

    // 显示光标。
    this.showCursor()

    setTimeout(() => {
      if (
        !this.isSelectedText &&
        this.$textarea &&
        parseInt(this.$textarea.style.height) > 0 &&
        parseInt(this.$textarea.style.width) > 0
      ) {
        this.blinkCursor()
      } else {
        this.hideCursor()
      }
    }, ANIMATION_DURATION)
  }

  // 选中的纯文本。
  get selectedText() {
    return this.textList
      .slice(this.selectionStartIndex, this.selectionEndIndex)
      .map(({ text }) => text)
      .join('')
  }

  // 选中的文本列表。
  get selectedTextList() {
    return this.getTextListByIndexes(
      this.selectionStartIndex,
      this.selectionEndIndex,
    )
  }

  // 获取指定索引范围内的文本列表。
  getTextListByIndexes(startIndex: number, endIndex: number) {
    return this.textList.slice(startIndex, endIndex)
  }

  // 是否选中了文本。
  get isSelectedText() {
    return !!this.selectedText
  }

  // 绘制选区。
  drawSelection() {
    // console.log(this.selectionCursors[0], this.selectionCursors[1])

    // 移除选区色块。
    this.hideSelectionColorRectIfNeed()

    // 先绘制光标。
    this.drawCursor()
    if (this.isSelectedText) {
      this.drawSelectionColorRect()

      // 隐藏光标。
      this.hideCursor()
    }

    this.focus()
  }

  // 聚焦。
  focus() {
    setTimeout(() => {
      // 聚焦，只有 setTimeout 中执行才能聚焦，否则会莫名其妙的失去焦点。
      this.$textarea?.focus()
    })
  }

  // 开始编辑。
  startEdit(cursor: 'pointer' | number[], silent: boolean) {
    // 设置文本矩阵绝对位置。
    this.setTextAbsRectMatrix()

    // 保存编辑器文本属性。
    this.preAttrs = cloneDeep(this.attrs)

    // 初始化。
    this.init(cursor)

    // 禁用缩放功能。
    this.transformer?.resizeEnabled(false)

    // 触发 textEditStart 事件。
    if (!silent) {
      this.fire(EGraphicEvent.TEXT_START_EDIT, {
        curSelectionIndexes: this.selectionIndexes,
      })
    }
  }

  // 退出编辑。
  exitEdit(silent: boolean) {
    // 如果退出编辑时，文字变化还未保存，则手动触发事件。
    if (this.hasUnrecordedTextOp) {
      this.#fireTextChangingEvent()
    }

    // 如果编辑前后属性不一致就触发 textChanged 事件。
    if (!isEqual(this.attrs, this.preAttrs)) {
      this.fire(EGraphicEvent.TEXT_CHANGED)
    }

    // 清理。
    this.clear()

    // 启用缩放功能。
    this.transformer?.resizeEnabled(true)

    // 触发 textExitEdit 事件。
    if (!silent) {
      this.fire(EGraphicEvent.TEXT_EXIT_EDIT, {
        curSelectionIndexes: this.selectionIndexes,
      })
    }
  }

  // 是否正在编辑。
  get isEditing() {
    return !!this.$textarea
  }

  // 清理。
  clear() {
    if (this.$textarea) {
      // 移除所有事件监听器。
      this.#listeners.forEach((off) => off())

      // 恢复鼠标样式。
      this.graphicEditor!.setCursorStyle('default')

      // 移除选中文本色块。
      this.removeSelectionColorRect()

      // 移除输入框和光标。
      document.body.removeChild(this.$textarea)

      this.$textarea = null
    }
  }

  // 获取当前操作的文字列表。
  getCurOperateTextList() {
    let indexes: [number, number] = [0, 0]
    if (this.isEditing) {
      if (this.isSelectedText) {
        indexes = [this.selectionStartIndex, this.selectionEndIndex]
      } else {
        indexes = [
          Math.max(0, this.selectionStartIndex - 1),
          Math.max(1, this.selectionStartIndex),
        ]
      }
    } else {
      indexes = [0, this.textList.length]
    }

    return this.getTextListByIndexes(...indexes)
  }

  // 获取字符样式值列表。
  getCharAttrList<A extends `${ETextAttr}`>(
    attrs: A[],
    mode: 'auto' | 'all' = 'auto',
  ) {
    const textList =
      mode === 'auto' ? this.getCurOperateTextList() : this.textList

    return textList.map((t) => pick(t, attrs))
  }

  // 设置字符样式值列表。
  setCharAttrList<A extends `${ETextAttr}`, V extends TextListItem[A]>(
    attr: A,
    value: V[],
    mode: 'auto' | 'all' = 'auto',
  ) {
    const { selfAdaptIfNeed } = this.useSizeSelfAdapter()
    const textList =
      mode === 'auto' ? this.getCurOperateTextList() : this.textList

    textList.map((t, i) => (t[attr] = value[i]))
    selfAdaptIfNeed()

    this.redrawText()
  }

  // 获取文字属性，如果存在多个属性则返回空字符串。
  getTextAttr<T extends `${ETextAttr}`, V extends TextListItem[T], M extends V>(
    attr: T,
    mode: 'auto' | 'all',
    mixedValue: M,
  ) {
    const textList =
      mode === 'auto' ? this.getCurOperateTextList() : this.textList

    const attrs = uniqBy(
      textList.map((textItem) => {
        switch (attr) {
          // 文字在跟图片在一起缩放时会缩放字号，所以可能出现很多小数位，这里保留 1 位小数。
          case ETextAttr.FONT_SIZE: {
            return roundTo1(textItem.fontSize)
          }

          default: {
            return textItem[attr]
          }
        }
      }),
      JSON.stringify,
    )
    if (attrs.length === 1) {
      return attrs[0] as V
    }

    switch (attr) {
      case ETextAttr.FONT_STYLE: {
        // 样式特殊处理，因为其中包含了斜体和粗体两个属性。
        const fontStyle = textList.map((t) => (t[attr] as string).split(' '))
        const weightList = fontStyle.map((f) => f[0])
        const styleList = fontStyle.map((f) => f[1])
        if (uniq(weightList).length === 1) {
          return [weightList[0], ''].join(' ') as V
        }

        if (uniq(styleList).length === 1) {
          return ['', styleList[0]].join(' ') as V
        }

        break
      }

      default: {
      }
    }

    return mixedValue
  }

  // 设置文字属性。
  setTextAttr<T>(
    attr: `${ETextAttr}`,
    value: T,
    mode: 'auto' | 'all' = 'auto',
  ) {
    const textList =
      mode === 'auto' ? this.getCurOperateTextList() : this.textList
    textList.forEach((textItem) => {
      switch (attr) {
        case ETextAttr.FONT_STYLE: {
          const [weight, style] = (value as string).split(' ')
          if (weight && style) {
            textItem[attr] = value as any
          } else {
            const [fontWeight, fontStyle] = (textItem[attr] as string).split(
              ' ',
            )
            if (weight) {
              textItem[attr] = [weight, fontStyle].join(' ') as any
            } else {
              textItem[attr] = [fontWeight, style].join(' ') as any
            }
          }

          break
        }

        default: {
          // @ts-ignore
          textItem[attr] = value

          break
        }
      }
    })

    this.refocusIfCan()
  }

  // 设置或获取 fontFamily
  fontFamily(fontFamily?: string) {
    if (isNil(fontFamily)) {
      return this.getTextAttr(ETextAttr.FONT_FAMILY, 'auto', '')
    }

    if (this.isEditing && !this.isSelectedText) {
      this.nextInsertTextStyle.fontFamily = fontFamily

      this.refocusIfCan()

      return fontFamily
    }

    const { selfAdaptIfNeed } = this.useSizeSelfAdapter()

    this.setTextAttr(ETextAttr.FONT_FAMILY, fontFamily)

    this.redrawText()

    selfAdaptIfNeed()

    // 同步框的尺寸。
    this.borderRect?.size(this.box.size())

    return fontFamily
  }

  // 设置或获取 fontSize
  fontSize(fontSize?: number) {
    if (isNil(fontSize)) {
      return this.getTextAttr(ETextAttr.FONT_SIZE, 'auto', -1)
    }

    if (this.isEditing && !this.isSelectedText) {
      this.nextInsertTextStyle.fontSize = fontSize

      this.refocusIfCan()

      return fontSize
    }

    const { selfAdaptIfNeed } = this.useSizeSelfAdapter()

    this.setTextAttr(ETextAttr.FONT_SIZE, fontSize)

    this.redrawText()

    selfAdaptIfNeed()

    // 同步框的尺寸。
    this.borderRect?.size(this.box.size())

    return fontSize
  }

  // 设置或获取 lineHeight
  lineHeight(lineHeight?: number) {
    if (isNil(lineHeight)) {
      return this.getTextAttr(ETextAttr.LINE_HEIGHT, 'auto', -1)
    }

    if (this.isEditing && !this.isSelectedText) {
      this.nextInsertTextStyle.lineHeight = lineHeight

      this.refocusIfCan()

      return lineHeight
    }

    const { selfAdaptIfNeed } = this.useSizeSelfAdapter()

    this.setTextAttr(ETextAttr.LINE_HEIGHT, lineHeight)

    this.redrawText()

    selfAdaptIfNeed()

    // 同步框的尺寸。
    this.borderRect?.size(this.box.size())

    return lineHeight
  }

  // 设置或获取 letterSpacing
  letterSpacing(letterSpacing?: number) {
    if (isNil(letterSpacing)) {
      return this.getTextAttr(ETextAttr.LETTER_SPACING, 'auto', -1)
    }

    if (this.isEditing && !this.isSelectedText) {
      this.nextInsertTextStyle.letterSpacing = letterSpacing

      this.refocusIfCan()

      return letterSpacing
    }

    const { selfAdaptIfNeed } = this.useSizeSelfAdapter()

    this.setTextAttr(ETextAttr.LETTER_SPACING, letterSpacing)

    this.redrawText()

    selfAdaptIfNeed()

    // 同步框的尺寸。
    this.borderRect?.size(this.box.size())

    return letterSpacing
  }

  // 设置或获取 textAlign
  align(align?: `${EAlign}`) {
    if (isNil(align)) {
      return this.graphic.align() as `${EAlign}`
    }

    this.graphic.align(align)

    this.redrawText()

    return align
  }

  // 设置或获取 verticalAlign
  verticalAlign(verticalAlign?: `${EVerticalAlign}`) {
    if (isNil(verticalAlign)) {
      return this.graphic.verticalAlign() as `${EVerticalAlign}`
    }

    this.graphic.verticalAlign(verticalAlign)

    this.redrawText()

    return verticalAlign
  }

  // 设置或获取 fill
  fill(fill?: string) {
    if (isNil(fill)) {
      return this.getTextAttr(ETextAttr.FILL, 'auto', '')
    }

    if (this.isEditing && !this.isSelectedText) {
      this.nextInsertTextStyle.fill = fill
      this.setCursorColor(fill)

      this.refocusIfCan()

      return fill
    }

    this.setTextAttr(ETextAttr.FILL, fill)

    this.redrawText()

    return fill
  }

  // 设置或获取 fillPriority
  fillPriority(fillPriority?: `${EFillPriority}`) {
    if (isNil(fillPriority)) {
      return this.getTextAttr(
        ETextAttr.FILL_PRIORITY,
        'auto',
        EFillPriority.MIXED,
      )
    }

    if (this.isEditing && !this.isSelectedText) {
      this.nextInsertTextStyle.fillPriority = fillPriority
      this.setCursorColor(fillPriority)

      this.refocusIfCan()

      return fillPriority
    }

    this.setTextAttr(ETextAttr.FILL_PRIORITY, fillPriority)

    this.redrawText()

    return fillPriority
  }

  // 设置或获取 linearGradientColorStops
  linearGradientColorStops(linearGradientColorStops?: [number, string][]) {
    if (isNil(linearGradientColorStops)) {
      return this.getTextAttr(ETextAttr.LINEAR_GRADIENT_COLOR_STOPS, 'auto', [])
    }

    if (this.isEditing && !this.isSelectedText) {
      this.nextInsertTextStyle.linearGradientColorStops =
        linearGradientColorStops
      this.setCursorColor('black')

      this.refocusIfCan()

      return linearGradientColorStops
    }

    this.setTextAttr(
      ETextAttr.LINEAR_GRADIENT_COLOR_STOPS,
      cloneDeep(linearGradientColorStops),
    )

    this.redrawText()

    return linearGradientColorStops
  }

  // 设置或获取 linearGradientDegree
  linearGradientDegree(linearGradientDegree?: number) {
    if (isNil(linearGradientDegree)) {
      return this.getTextAttr(ETextAttr.LINEAR_GRADIENT_DEGREE, 'auto', -1)
    }

    if (this.isEditing && !this.isSelectedText) {
      this.nextInsertTextStyle.linearGradientDegree = linearGradientDegree

      this.refocusIfCan()

      return linearGradientDegree
    }

    this.setTextAttr(ETextAttr.LINEAR_GRADIENT_DEGREE, linearGradientDegree)

    this.redrawText()

    return linearGradientDegree
  }

  // 设置或获取 textDecoration
  textDecoration(textDecoration?: string) {
    if (isNil(textDecoration)) {
      return this.getTextAttr(ETextAttr.TEXT_DECORATION, 'auto', '')
    }

    if (this.isEditing && !this.isSelectedText) {
      this.nextInsertTextStyle.textDecoration = textDecoration

      this.refocusIfCan()

      return textDecoration
    }

    this.setTextAttr(ETextAttr.TEXT_DECORATION, textDecoration)

    this.redrawText()

    return textDecoration
  }

  // 设置或获取 fontStyle
  fontStyle(fontStyle?: string) {
    if (isNil(fontStyle)) {
      return this.getTextAttr(ETextAttr.FONT_STYLE, 'auto', '')
    }

    if (this.isEditing && !this.isSelectedText) {
      this.nextInsertTextStyle.fontStyle = fontStyle

      this.refocusIfCan()

      return fontStyle
    }

    const { selfAdaptIfNeed } = this.useSizeSelfAdapter()

    this.setTextAttr(ETextAttr.FONT_STYLE, fontStyle)

    this.redrawText()

    selfAdaptIfNeed()

    // 同步框的尺寸。
    this.borderRect?.size(this.box.size())

    return fontStyle
  }

  // 重新聚焦如果可以的话。
  refocusIfCan() {
    if (this.isEditing && !this.isSelectedText && !this.cursorIsHide) {
      this.focus()
    }
  }

  // 获取文字。
  getText() {
    return this.textList.reduce((text, t) => text + t.text, '')
  }

  // 重写父类的 x 方法。
  x(x?: number) {
    if (isNil(x)) {
      return super.x()
    }

    x = super.x(x)

    // 同步文字和边框的 x 值。
    const contentX = this.box.x()
    this.borderRect?.x(contentX)
    this.graphic.x(contentX)

    this.hideSelectionAndRedrawIfNeed()

    return x
  }

  // 重写父类的 y 方法。
  y(y?: number) {
    if (isNil(y)) {
      return super.y()
    }

    y = super.y(y)

    // 同步文字和边框的 y 值。
    const contentY = this.box.y()
    this.borderRect?.y(contentY)
    this.graphic.y(contentY)

    this.hideSelectionAndRedrawIfNeed()

    return y
  }

  // 重写父类的 width 方法。
  width(width?: number) {
    if (isNil(width)) {
      return super.width()
    }

    const { selfAdaptHeight } = this.useSizeSelfAdapter('height')

    super.width(width)

    // 同步框的宽度。
    const contentWidth = this.box.width()
    this.borderRect?.width(contentWidth)

    this.redrawText()

    this.hideSelectionAndRedrawIfNeed()

    selfAdaptHeight()

    return width
  }

  // 重写父类的 height 方法。
  height(height?: number) {
    if (isNil(height)) {
      return super.height()
    }

    super.height(height)

    // 同步框的高度。
    const contentHeight = this.box.height()
    this.borderRect?.height(contentHeight)

    this.redrawText()

    return height
  }

  // 重写父类的 attrs 方法。
  get attrs() {
    return {
      ...super.attrs,
      text: this.getText(),
      align: this.align(),
      verticalAlign: this.verticalAlign(),
      fontFamily: this.getTextAttr(ETextAttr.FONT_FAMILY, 'all', ''),
      fontSize: this.getTextAttr(ETextAttr.FONT_SIZE, 'all', -1),
      letterSpacing: this.getTextAttr(ETextAttr.LETTER_SPACING, 'all', -1),
      lineHeight: this.getTextAttr(ETextAttr.LINE_HEIGHT, 'all', -1),
      fill: this.getTextAttr(ETextAttr.FILL, 'all', ''),
      fontStyle: this.getTextAttr(ETextAttr.FONT_STYLE, 'all', ''),
      linearGradientColorStops: this.getTextAttr(
        ETextAttr.LINEAR_GRADIENT_COLOR_STOPS,
        'all',
        [],
      ),
      linearGradientDegree: this.getTextAttr(
        ETextAttr.LINEAR_GRADIENT_DEGREE,
        'all',
        -1,
      ),
      textDecoration:
        this.getTextAttr(ETextAttr.TEXT_DECORATION, 'all', '') || 'none',
      textList: this.getTextList(),
    }
  }

  // 获取文字列表。
  getTextList() {
    return cloneDeep(this.textList)
  }

  // 文本长度。
  get textLength() {
    return this.textList.filter(({ text }) => !!text).length
  }

  // 重写父类的 remove 方法。
  remove() {
    // 移除输入框。
    this.clear()

    return super.remove()
  }

  // 根据文本内容自适应宽度。
  selfAdaptWidth() {
    if (this.width() === this.textWidth) {
      return
    }

    this.width(this.textWidth)

    this.fire(EGraphicEvent.DIMENSION_CHANGE)
  }

  // 根据文本内容自适应高度。
  selfAdaptHeight() {
    if (this.height() === this.textHeight) {
      return
    }

    this.height(this.textHeight)

    this.fire(EGraphicEvent.DIMENSION_CHANGE)
  }

  // 文本高度。
  get textHeight() {
    return roundTo1(
      sum(
        this.textMatrix.map((row) =>
          max(row.map(({ lineHeightPX }) => lineHeightPX)),
        ),
      ),
    )
  }

  // 文本宽度。
  get textWidth() {
    const textMatrix = this.getTextMatrix({ width: 0 })

    return roundTo1(
      Math.ceil(
        max(textMatrix.map((row) => sum(row.map(({ width }) => width))))!,
      ),
    )
  }

  // 自动调整文本宽度。
  autoAdjustTextWidth() {
    const width = this.width()
    const { textWidth } = this

    // 剩余空白空间。
    const remainSpace = width - textWidth

    switch (this.align()) {
      case EAlign.CENTER: {
        this.moveX(remainSpace / 2)

        break
      }

      case EAlign.RIGHT: {
        this.moveX(remainSpace)

        break
      }

      default: {
        break
      }
    }

    this.selfAdaptWidth()
    this.selfAdaptHeight()
  }

  // 自动调整文本高度。
  autoAdjustTextHeight() {
    this.selfAdaptHeight()
  }

  // 当前是否是自适应宽度。
  get isAdaptedWidth() {
    return isApproximatelyEqual(this.width(), this.textWidth, 1)
  }

  // 当前是否是自适应高度。
  get isAdaptedHeight() {
    return isApproximatelyEqual(this.height(), this.textHeight, 1)
  }

  // 图形轮廓实际矩形框，用来表达实际展示的最大轮廓。
  get outlineActualRectBox() {
    return this.actualRectBox
  }

  // 用于命中检测的图形实际矩形框。
  // get hitActualRectBox() {
  //   const textWidth = max(
  //     this.textAbsRectMatrix.map((row) => sum(row.map((r) => r.width ?? 0))),
  //   )!

  //   const textHeight = sum(
  //     this.textAbsRectMatrix.map((row) => max(row.map((r) => r.height ?? 0))),
  //   )!

  //   const { x, y, x2, y2 } = this.calcActualRectBox

  //   const rectBoxes = [
  //     { x2, y2 },
  //     {
  //       x2: roundTo(x + textWidth),
  //       y2: roundTo(y + textHeight),
  //     },
  //   ]

  //   const maxX2 = max(rectBoxes.map(({ x2 }) => x2)) ?? 0
  //   const maxY2 = max(rectBoxes.map(({ y2 }) => y2)) ?? 0
  //   const width = maxX2 - x
  //   const height = maxY2 - y

  //   return {
  //     x,
  //     x1: roundTo(x + width / 2),
  //     x2: maxX2,
  //     width,
  //     y,
  //     y1: roundTo(y + height / 2),
  //     y2: maxY2,
  //     height,
  //   }
  // }

  // 重新绘制选区。
  redrawSelection() {
    this.#setSelectionByIndex(this.selectionIndexes, true)
  }

  // 存在未记录的文本操作。
  get hasUnrecordedTextOp() {
    return mergeTextChangeOp(this.textChangeOps).length > 0
  }

  // 加载文字列表中文字。
  loadTextListFont(textList: TextListItem[]) {
    return Promise.all(
      Object.entries(getFontFamilyTextMap(textList)).map(
        ([fontFamily, text]) => {
          return this.loadFont(fontFamily, [...new Set(text)].join(''))
        },
      ),
    )
  }

  // 设置文本列表。
  async setTextList(textList: TextListItem[]) {
    this.textList = textList

    await this.loadTextListFont(this.textList)

    this.redrawText()
  }

  // 获取文字矩阵。
  getTextMatrix({ width, height }: { width?: number; height?: number } = {}) {
    return getTextMatrix(this.textList, {
      align: this.align(),
      verticalAlign: this.verticalAlign(),
      containerWidth: width ?? this.width(),
      containerHeight: height ?? this.height(),
      checkFont: this.checkFont,
    })
  }

  // 设置文字矩阵。
  setTextMatrix() {
    // console.log('setTextMatrix')
    this.textMatrix = this.getTextMatrix()

    // console.timeEnd('setTextMatrix')

    this.graphic.textMatrix = this.textMatrix

    this.setTextAbsRectMatrix()
    if (this.isEditing) {
      this.redrawSelection()
    }
  }

  // 重绘文字。
  redrawText() {
    this.setTextMatrix()
    this.graphic._requestDraw()
  }

  // 宽高自适应。
  useSizeSelfAdapter(type?: 'width' | 'height') {
    let isAdaptedHeight = false
    let isAdaptedWidth = false
    const isSelfAdaptWidth = !type || type === 'width'
    const isSelfAdaptHeight = !type || type === 'height'
    if (isSelfAdaptWidth) {
      isAdaptedWidth = this.isAdaptedWidth
    }

    if (isSelfAdaptHeight) {
      isAdaptedHeight = this.isAdaptedHeight
    }

    const selfAdaptWidth = () => {
      if (isAdaptedWidth) {
        this.selfAdaptWidth()
      }
    }
    const selfAdaptHeight = () => {
      if (isAdaptedHeight) {
        this.selfAdaptHeight()
      }
    }

    const selfAdaptIfNeed = () => {
      selfAdaptWidth()

      selfAdaptHeight()
    }

    return {
      isAdaptedHeight,
      isAdaptedWidth,
      selfAdaptHeight,
      selfAdaptWidth,
      selfAdaptIfNeed,
    }
  }

  // 隐藏选区并重绘。
  hideSelectionAndRedrawIfNeed() {
    if (this.isEditing) {
      this.hideSelection()
      this.#debouncedRedrawSelection()
    }
  }

  // 是否应该禁用修改属性。
  shouldDisableModifyAttr(attr: string) {
    if (attr === 'width') {
      return !this.firstDrawIsComplete || this.isAdaptedWidth
    }

    if (attr === 'height') {
      return !this.firstDrawIsComplete || this.isAdaptedHeight
    }

    return false
  }

  // 操作属性。
  getOperateAttrs(): ReturnType<Graphic['getOperateAttrs']> {
    return {
      enabledAnchors: RESIZE_ANCHOR_LIST,
      keepRatio: false,
      borderEnabled: true,
      resizeEnabled: this.firstDrawIsComplete,
      rotateEnabled: false,
      draggable: this.firstDrawIsComplete && !this.isEditing,
      maskEnabled: this.firstDrawIsComplete && !this.isEditing,
      croppable: false,
      selectable: true,
      hoverEnabled: true,
      pointerStyle: this.graphicEditor?.normalGraphics.some(
        (g) => g instanceof GraphicText && g.isEditing,
      )
        ? 'text'
        : 'default',
      hasRadius: false,
    }
  }
}
