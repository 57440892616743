import { ANIMATION_DURATION } from '@lanyan/constant/src/common'
import { AnyObject } from '@lanyan/type'
import { isFunction, isNumber, noop, partial } from 'lodash-es'

import { formatMSToDuration } from './date'

// import { Remote, wrap } from 'comlink'

// 等待指定毫秒数。
export function wait(msOrFn: number | (() => void), ms?: number) {
  if (isNumber(msOrFn)) {
    return new Promise((r) => setTimeout(r, msOrFn))
  } else if (isFunction(msOrFn)) {
    setTimeout(msOrFn, ms ?? 0)
  }
}

// 等待动画结束。
export const waitAnimation = partial(wait, ANIMATION_DURATION)

// 分离式 promise
export const usePromise = <T>() => {
  const p: {
    resolve: (value?: T) => void
    reject: (value: any) => void
    promise: Promise<T>
  } = {
    resolve: noop,
    reject: noop,
    promise: Promise.resolve<T>(undefined as unknown as T),
  }

  p.promise = new Promise<T>((resolve, reject) => {
    p.resolve = resolve as (value?: T) => {}

    p.reject = reject
  })

  return p
}

// 将普通函数转化成返回 promise 的异步函数。
export const promisify = <T, P extends any[]>(
  fn: (...args: P) => T | Promise<T>,
) => {
  return async (...args: P) => {
    try {
      const res = await fn(...args)

      return res as T
    } catch (error) {
      throw error
    }
  }
}

// 解析 json 字符串，当解析失败，不会抛出异常。
export const parseJSON = <T>(...params: Parameters<typeof JSON.parse>) => {
  try {
    return JSON.parse(...params) as T
  } catch (error) {
    return undefined
  }
}

// 四舍五入到指定小数点。
export const toFixed = (num: number, digits: number) => {
  return Number(num.toFixed(digits ?? 0))
}

// 限制数字在一定范围内。
export const clampNumber = (num: number, minNum: number, maxNum: number) => {
  return Math.min(Math.max(num, minNum), maxNum)
}

// 获取扩展名。
export const getFileExtension = (str: string) => {
  return str.split('?').shift()!.split('.').pop()!
}

// 通过 url 获取文件名。
export const getFileNameFromUrl = (url: string) => {
  const filename = url.split('/').pop()!

  return filename.split('.').slice(0, -1).join('.')
}

// 重命名对象中的键，返回一个新对象，其中包含重命名后的键。
export const renameObjKey = <
  T extends AnyObject,
  U extends {
    [K in keyof U]: K extends keyof T ? PropertyKey : 'Error: key not in T'
  },
>(
  originalObj: T,
  keyMap: U,
) => {
  type IRenamedObj = {
    [K in keyof T as K extends keyof U ? U[K] : K]: T[K]
  }
  const renamedObj: IRenamedObj = {} as IRenamedObj

  for (const [key, value] of Object.entries(originalObj)) {
    const newKey = (keyMap[key as keyof U] || key) as keyof IRenamedObj

    renamedObj[newKey] = value
  }

  return renamedObj
}

/**
 * 在下一个事件循环周期中调用回调函数。
 * 如果浏览器支持 Promise，则使用 Promise。resolve（）。then（） 的方式调用回调函数。
 * 如果浏览器不支持 Promise 但支持 requestAnimationFrame，则使用 requestAnimationFrame 的方式调用回调函数。
 * 如果浏览器都不支持，则使用 setTimeout 的方式调用回调函数。
 */
export function nextTick(callback: () => void) {
  if (Promise) {
    // 如果浏览器支持 Promise，则使用 Promise。resolve（）。then（） 的方式调用回调函数。
    Promise.resolve().then(callback)

    return
  }

  if (requestAnimationFrame) {
    // 如果浏览器不支持 Promise 但支持 requestAnimationFrame，则使用 requestAnimationFrame 的方式调用回调函数。
    requestAnimationFrame(callback)

    return
  }

  // 如果浏览器都不支持，则使用 setTimeout 的方式调用回调函数。
  const timer = setTimeout(() => {
    callback()

    clearTimeout(timer)
  }, 0)

  return
}

// 获取滚动条宽度。
export const getScrollbarWidth = () => {
  // 创建一个测试元素，设置宽度为 100px，高度为 0，然后将其样式设为隐藏和绝对定位。
  const testElement = document.createElement('div')

  testElement.style.width = '100px'

  testElement.style.height = '0'

  testElement.style.overflow = 'scroll'

  testElement.style.position = 'absolute'

  testElement.style.top = '-9999px'

  document.body.appendChild(testElement)

  // 计算滚动条的宽度。
  const scrollbarWidth = testElement.offsetWidth - testElement.clientWidth

  // 移除测试元素。
  document.body.removeChild(testElement)

  return scrollbarWidth
}

// 将秒转化成时分秒的字符串。
export const formatSecondToTimeString = (second: number) => {
  const totalSeconds = Math.floor(second)

  const minutes = Math.floor(totalSeconds / 60)
  const seconds = totalSeconds % 60

  if (minutes > 0 && seconds > 0) {
    return `${minutes} 分 ${seconds} 秒`
  } else if (minutes > 0 && seconds === 0) {
    return `${minutes} 分`
  } else if (minutes === 0 && seconds > 0) {
    return `${seconds} 秒`
  }

  return `0 分`
}

// 将秒转化成倒计时字符串。
export const formatSecondToCountdownTimeString = (second: number) => {
  const ms = second * 1000

  if (second >= 1000 * 60 * 60) {
    return formatMSToDuration(ms, { format: 'HH 时mm 分ss 秒' })
  } else if (ms >= 1000 * 60) {
    return formatMSToDuration(ms, { format: 'mm 分ss 秒' })
  } else if (ms >= 0) {
    return `${Math.floor(second)} 秒`
  }

  return '-'
}

// 轮训。
export const poll = <T>({
  interval = 1000,
  timeout = 60 * 1000,
  fn,
  checker = (value: T) => !!value,
  onSuccess,
  onFail,
  onFinally,
}: {
  interval?: number
  timeout?: number
  fn: () => T | Promise<T>
  checker?: (res: T) => boolean | Promise<boolean>
  onSuccess?: () => void
  onFail?: () => void
  onFinally?: (res: boolean) => void
}) => {
  let recursionTimer: NodeJS.Timeout

  const resolve = () => {
    // 在下一个事件循环周期中调用回调函数，否则在各个回调函数中清除的定时器可能是旧的。
    setTimeout(() => {
      clearTimeout(recursionTimer)
    })
    clearTimeout(timeoutTimer)
  }

  const timeoutTimer = setTimeout(() => {
    resolve()
    onFail?.()
    onFinally?.(false)
  }, timeout)

  const recursionTimeout = () => {
    recursionTimer = setTimeout(async () => {
      const res = await fn()

      const checkResult = await checker(res)

      if (checkResult) {
        resolve()
        onSuccess?.()
        onFinally?.(true)
      } else {
        recursionTimeout()
      }
    }, interval)
  }

  recursionTimeout()

  return resolve
}

export const numberToChinese = (num: number) => {
  const arr1 = new Array(
    '零',
    '一',
    '二',
    '三',
    '四',
    '五',
    '六',
    '七',
    '八',
    '九',
  )
  const arr2 = new Array('', '十', '百') // 可继续追加更高位转换值。
  if (!num || isNaN(num)) {
    return '零'
  }

  const english = num.toString().split('')
  let result = ''

  for (let i = 0; i < english.length; i++) {
    const des_i = english.length - 1 - i // 倒序排列设值。
    result = arr2[i] + result
    const arr1_index = english[des_i]
    result = arr1[arr1_index as unknown as number] + result
  }

  // 将【零千、零百】换成【零】 【十零】换成【十】
  result = result.replace(/零(百|十)/g, '零').replace(/十零/g, '十')

  // 合并中间多个零为一个零。
  result = result.replace(/零+/g, '零')

  // 移除末尾的零。
  result = result.replace(/零+$/, '')

  // 将【零一十】换成【零十】
  // result = result。replace（/零一十/g， ‘零十‘）；//貌似正规读法是零一十。
  // 将【一十】换成【十】
  result = result.replace(/^一十/g, '十')

  return result
}

// 返回上一页。
export const goBack = (router: {
  go: (step: number) => void
  push: (to: string) => void
}) => {
  if (window.history.state.back) {
    router.go(-1)
  } else {
    router.push('/')
  }
}

// 数字转字母大写。
export const numberToCapital = (num: number) => {
  if (num >= 0 && num <= 26) {
    return String.fromCharCode(num + 65)
  }

  return null // 或其他错误处理方式。
}

// 移除 undefined 属性
export const removeUndefinedProp = (obj: Record<string, any>) => {
  for (const key in obj) {
    if (obj[key] === undefined) {
      delete obj[key]
    }
  }

  return obj
}

// 从一个 worker 中返回一个指定的导出函数，这个函数可以实现并发调用。
// export const concurrency = <
//   T extends Record<string, (...args: any[]) => Promise<any>>,
//   F extends keyof T = keyof T,
// >(
//   workerURL: URL,
//   fnName: F,
//   {
//     maxWorkNum = navigator.hardwareConcurrency,
//   }: {
//     maxWorkNum?: number
//   } = {},
// ) => {
//   // 最大并发数，至少要有 1 个，但是不能超过硬件支持的最大并发数。
//   maxWorkNum = max([min([maxWorkNum, navigator.hardwareConcurrency]), 1])!

//   type IWorker = {
//     calls: number
//     worker: Remote<T>
//     terminate: () => void
//   }

//   // 创建一个 worker
//   const createWorker = () => {
//     const worker = new Worker(workerURL, {
//       type: 'module',
//     })

//     return {
//       calls: 0,
//       worker: wrap<T>(worker),
//       terminate: () => {
//         worker.terminate()
//       },
//     }
//   }

//   // 线程池，先默认创建一个 worker
//   const workers: IWorker[] = [createWorker()]

//   // 1 秒后再创建剩余的 worker，因为 worker 创建后会有延迟才能接收 message
//   setTimeout(() => {
//     new Array(maxWorkNum - 1).fill(null).forEach(() => {
//       workers.push(createWorker())
//     })
//   }, 1000)

//   // 指定函数的参数类型。
//   type FnP = Parameters<T[F]>

//   // 指定函数的返回值类型。
//   type FnR = Awaited<ReturnType<T[F]>>

//   return async (...args: FnP) => {
//     // 找出调用次数最少的 worker
//     const worker = minBy(workers, 'calls')!

//     // console.log(workers.map(({ calls }) => calls))

//     worker.calls += 1
//     const [callError, callResult] = await to<FnR>(
//       worker.worker[fnName](...args),
//     )
//     if (callError) {
//       console.error(callError)
//     }

//     worker.calls -= 1

//     return callResult
//   }
// }

// 定义类型谓词函数。
export const isTruthy = <T>(
  value: T,
): value is Exclude<T, false | '' | 0 | null | undefined> => !!value

const CHINESE_RADICALS_MAP = {
  '⺎': '兀',
  '⺟': '母',
  '⺠': '民',
  '⻁': '虎',
  '⻄': '西',
  '⻅': '见',
  '⻆': '角',
  '⻉': '贝',
  '⻋': '车',
  '⻓': '长',
  '⻔': '门',
  '⻘': '青',
  '⻙': '韦',
  '⻚': '页',
  '⻛': '风',
  '⻜': '飞',
  '⻝': '食',
  '⻢': '马',
  '⻣': '骨',
  '⻤': '鬼',
  '⻥': '鱼',
  '⻦': '鸟',
  '⻧': '卤',
  '⻨': '麦',
  '⻩': '黄',
  '⻪': '黾',
  '⻬': '齐',
  '⻮': '齿',
  '⻯': '竜',
  '⻰': '龙',
  '⻳': '龟',
} as Record<string, string>

export const formatPastedContent = (pastedData: string) => {
  return pastedData.replace(
    /[\u2E80-\u2EFF]/g,
    (match) => CHINESE_RADICALS_MAP[match] || match,
  )
}
