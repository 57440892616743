import { isImage, isVideo } from '../predicate'
import { Actor } from './materials/Actor'
import { Arrow } from './materials/Arrow'
import { Circle } from './materials/Circle'
import { Ellipse } from './materials/Ellipse'
import { Image } from './materials/Image'
import { ImageBackground } from './materials/ImageBackground'
import { Line } from './materials/Line'
import { PureBackground } from './materials/PureBackground'
import { Rect } from './materials/Rect'
import { RegularPolygon } from './materials/RegularPolygon'
import { Text } from './materials/Text'
import { Video } from './materials/Video'
import { VideoBackground } from './materials/VideoBackground'
import {
  IBackgroundMaterial,
  ICornerRadiusMaterial,
  ILineShapeMaterial,
  IMaterial,
  IMediaMaterial,
  ISymmetryShapeMaterial,
} from './type'

// 检查是否是数字人素材。
export const isActorMaterial = (material: IMaterial): material is Actor => {
  return material instanceof Actor
}

// 检查是否是文本素材。
export const isTextMaterial = (material: IMaterial): material is Text => {
  return material instanceof Text
}

// 检查是否是矩形素材。
export const isRectMaterial = (material: IMaterial): material is Rect => {
  return material instanceof Rect
}

// 检查是否是等边矩形素材。
export const isRegularPolygonMaterial = (
  material: IMaterial,
): material is RegularPolygon => {
  return material instanceof RegularPolygon
}

// 检查是否是圆形素材。
export const isCircleMaterial = (material: IMaterial): material is Circle => {
  return material instanceof Circle
}

// 检查是否是椭圆素材。
export const isEllipseMaterial = (material: IMaterial): material is Ellipse => {
  return material instanceof Ellipse
}

// 检查是否是图片素材。
export const isImageMaterial = (material: IMaterial): material is Image => {
  return material instanceof Image
}

// 检查是否是视频素材。
export const isVideoMaterial = (material: IMaterial): material is Video => {
  return material instanceof Video
}

// 检查是否是线段素材。
export const isLineMaterial = (material: IMaterial): material is Line => {
  return material instanceof Line
}

// 检查是否是箭头素材。
export const isArrowMaterial = (material: IMaterial): material is Arrow => {
  return material instanceof Arrow
}

// 检查是否是纯背景素材。
export const isPureBackgroundMaterial = (
  material: IMaterial,
): material is PureBackground => {
  return material instanceof PureBackground
}

// 检查是否是背景素材。
export const isBackgroundMaterial = (
  material: IMaterial,
): material is IBackgroundMaterial => {
  return (
    isPureBackgroundMaterial(material) || isMediaBackgroundMaterial(material)
  )
}

// 检查是否是视频背景素材。
export const isVideoBackgroundMaterial = (
  material: IMaterial,
): material is VideoBackground => {
  return material instanceof VideoBackground
}

// 检查是否是图片背景素材。
export const isImageBackgroundMaterial = (
  material: IMaterial,
): material is ImageBackground => {
  return material instanceof ImageBackground
}

// 检查是否是媒体背景素材。
export const isMediaBackgroundMaterial = (
  material: IMaterial,
): material is VideoBackground | ImageBackground => {
  return (
    isVideoBackgroundMaterial(material) || isImageBackgroundMaterial(material)
  )
}

// 检查是否是媒体素材。
export const isMediaMaterial = (
  material: IMaterial,
): material is IMediaMaterial => {
  return isImageMaterial(material) || isVideoMaterial(material)
}

// 检查是否是线段素材。
export const isLineShapeMaterial = (
  material: IMaterial,
): material is ILineShapeMaterial => {
  return isLineMaterial(material) || isArrowMaterial(material)
}

// 检查是否是对称图形素材。
export const isSymmetryShapeMaterial = (
  material: IMaterial,
): material is ISymmetryShapeMaterial => {
  return isCircleMaterial(material) || isRegularPolygonMaterial(material)
}

// 检查是否是不对称图形素材。
export const isDissymmetryShapeMaterial = (
  material: IMaterial,
): material is ISymmetryShapeMaterial => {
  return isEllipseMaterial(material)
}

// 检查是否是图形素材。
export const isShapeMaterial = (
  material: IMaterial,
): material is ILineShapeMaterial | Rect | ISymmetryShapeMaterial => {
  return (
    isLineShapeMaterial(material) ||
    isRectMaterial(material) ||
    isSymmetryShapeMaterial(material) ||
    isDissymmetryShapeMaterial(material)
  )
}

// 检查是否是圆角素材。
export const isCornerRadiusMaterial = (
  material: IMaterial,
): material is ICornerRadiusMaterial => {
  return isImageMaterial(material) || isRectMaterial(material)
}

// 是否是图片参数。
export const isImageParams = ({ url, file }: { url?: string; file?: File }) => {
  const sourceUrl = url ?? file

  return sourceUrl ? isImage(sourceUrl) : false
}

// 是否是视频参数。
export const isVideoParams = ({ url, file }: { url?: string; file?: File }) => {
  const sourceUrl = url ?? file

  return sourceUrl ? isVideo(sourceUrl) : false
}
