<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'App',
})
</script>
<template>
  <ConfigProvider
    :locale="locale"
    :theme="theme"
  >
    <RouterView class="h-full overflow-hidden" />
  </ConfigProvider>
</template>

<script setup lang="ts" name="App">
import { ConfigProvider } from 'ant-design-vue'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import { computed } from 'vue'

import { businessColors } from '#tailwind'

// 主题
const theme = {
  token: {
    colorPrimary: businessColors.primary,
    colorInfo: businessColors.primary,
  },
}
const locale = computed(() => zhCN)
</script>
