// 视频展示类型枚举
export enum EFoldStatus {
  FOLD = 'fold',
  UNFOLD = 'unfold',
}

// 版本
export enum EVersion {
  LAN_YAN = 'lanYan',
  COURSE = 'course',
}
