import { commonStorage } from '@lanyan/util'
import { defineStore } from 'pinia'

import { EFoldStatus, EVersion } from '@/constant/home'

interface IGlobalStore {
  foldStatus: EFoldStatus
  version: EVersion
}

export const useGlobalStore = defineStore('global', {
  state: (): IGlobalStore => {
    return {
      foldStatus: EFoldStatus.FOLD,

      version: EVersion.COURSE,
    }
  },
  actions: {
    async getFoldStatus() {
      this.foldStatus =
        ((await commonStorage.getItem(
          'foldStatus',
        )) as unknown as EFoldStatus) ?? EFoldStatus.FOLD
    },
    setFoldStatus(status: EFoldStatus) {
      this.foldStatus = status
      commonStorage.setItem('foldStatus', status)
    },
    setVersion(version: EVersion) {
      this.version = version
    },
    getVersion() {
      return this.version
    },
  },
})
